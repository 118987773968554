<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>
    <div v-if="!loading" class="elite-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("BOOKINGS.BOOKING_DETAILS") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("BOOKINGS.BOOKINGS_LIST") }}</span>
              </li>
              <li>
                <span>{{ booking.code }}</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- region action/check in/check out -->
        <div class="view-details-header-right">
          <!-- edit -->
          <button class="default-outline" @click="goToPay" v-if="canPay">
            <i class="far fa-dollar-sign"></i>
            <span>{{ $t("SALES_INVOICES.PAY") }}</span>
          </button>

          <!-- checkin -->
          <button
            class="check-in"
            @click="checkInBooking"
            v-if="canCheckInBooking"
          >
            <img src="/img/kw-arrival.svg" alt="" />
            <span>{{ $t("BOOKINGS.CHECK_IN") }}</span>
          </button>

          <!-- checkout -->
          <button
            class="check-out"
            @click="checkOutBooking"
            v-if="canCheckOutBooking"
          >
            <img src="/img/kw-exit.svg" alt="icon" />
            <span>{{ $t("BOOKINGS.CHECK_OUT") }}</span>
          </button>

          <!-- checkin -->
          <button class="cancel" @click="cancelBooking" v-if="canCancelBooking">
            <i class="fal fa-times-circle"></i>
            <span>{{ $t("COMMON.CANCEL") }}</span>
          </button>

          <!-- edit -->
          <button class="edit" @click="onEditBooking" v-if="canEdit">
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT_INFOS") }}</span>
          </button>
        </div>
        <!-- endregion -->
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="booking"
      >
        <tab-pane title="booking" id="1" :active="true">
          <span slot="title">
            <!-- <i class="ni ni-cloud-upload-96"></i> -->
            {{ $t("COMMON.SUMMARY") }}
          </span>
          <booking-view-global
            :booking="booking"
            :sales-order="salesOrder"
            :sales-invoice="salesInvoice"
            @onChargeDifferenceDone="onChargeDifferenceDone"
          />
        </tab-pane>
        <tab-pane title="spot" id="2" :active="true">
          <span slot="title">
            {{ $t("COMMON.SPOT") }}
          </span>
          <booking-view-spot :booking="booking" />
        </tab-pane>
        <tab-pane
          v-if="this.booking.customer"
          title="customer"
          id="3"
          :active="true"
        >
          <span slot="title">
            {{ $t("COMMON.CUSTOMER") }}
          </span>
          <booking-view-customer :booking="booking" />
        </tab-pane>
        <tab-pane
          title="files"
          id="998"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span slot="title">
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="getFilesObject()" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            {{ $t("COMMON.LOGS") }}
          </span>
          <booking-view-logs :organization="booking.organization" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";

import { cloneDeep } from "lodash";
import { Tabs, TabPane } from "@/components";
import BookingViewGlobal from "../partials/BookingViewGlobal.vue";
import BookingViewSpot from "../partials/BookingViewSpot.vue";
import BookingViewCustomer from "../partials/BookingViewCustomer.vue";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_VALIDATED,
} from "@/constants/invoices";

import {
  BOOKING_STATUS_ARRIVED,
  BOOKING_STATUS_CANCELED,
  BOOKING_STATUS_FINISHED,
  BOOKING_STATUS_GUARANTEED,
  BOOKING_STATUS_NOT_GUARANTEED,
} from "@/constants/common";

import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import BookingViewLogs from "../partials/BookingViewLogs.vue";
import ListFileComponent from "@/components/File/ListFileComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    BookingViewGlobal,
    BookingViewSpot,
    BookingViewCustomer,
    BookingViewLogs,
    ListFileComponent,
  },

  mixins: [],

  props: {
    bookingId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      booking: null,
      salesInvoice: null,
      salesOrder: null,
      loading: true,
      isLoadingCancel: false,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      INVOICE_STATUS_VALIDATED: INVOICE_STATUS_VALIDATED,
      INVOICE_STATUS_PAID: INVOICE_STATUS_PAID,
    };
  },

  computed: {
    canCheckInBooking() {
      return (
        this.booking &&
        [BOOKING_STATUS_GUARANTEED, BOOKING_STATUS_NOT_GUARANTEED].includes(
          this.booking?.status
        )
      );
    },

    canCheckOutBooking() {
      return (
        this.booking && (this.booking.status == BOOKING_STATUS_ARRIVED)
      );
    },

    canCancelBooking() {
      return (
        this.booking &&
        [BOOKING_STATUS_GUARANTEED, BOOKING_STATUS_NOT_GUARANTEED].includes(
          this.booking.status
        )
      );
    },

    canEdit() {
      let flag = [BOOKING_STATUS_CANCELED, BOOKING_STATUS_FINISHED].includes(
        this.booking?.status
      );

      return (
        this.$currentUserCan(this.$permissions.PERM_EDIT_BOOKINGS) && !flag
      );
    },

    canPay() {
      return this.salesOrder?.recipient && !this.salesInvoice;
    },
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        this.loading = true;

        // Recuperation du booking
        await this.$store.dispatch("bookings/get", this.bookingId);
        this.booking = this.$store.getters["bookings/booking"];

        // Recuperation de la facture | TODO: TROUVER UNE MEILLEURE SOLUTION
        if (this.booking.invoice_id) {
          await this.$store.dispatch(
            "salesInvoices/get",
            this.booking.invoice_id
          );
          this.salesInvoice = this.$store.getters["salesInvoices/salesInvoice"];
        }

        // Recuperation de la commande
        await this.$store.dispatch(
          "salesOrders/get",
          this.booking.orderItem.salesOrder.id
        );

        this.salesOrder = this.$store.getters["salesOrders/salesOrder"];

        this.$emit("onBookingViewing", this.booking.orderItem.salesOrder.id);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async onChargeDifferenceDone() {
      await this.get();
    },

    goToPay() {
      this.$emit("onOpenConfirmBookingModal", this.salesOrder);
    },

    onViewSalesInvoice(invoice) {
      let routeParams = {
        name: "List SalesInvoices",
        query: { id: invoice.id, action: QUERY_ACTIONS_VIEW },
      };

      let route = this.$router.resolve(routeParams);

      history.pushState({}, null, route.href);

      this.$router.push(routeParams);
    },

    checkInBooking() {
      this.$emit("onCheckInBooking", {
        booking: this.booking,
        invoice: this.salesInvoice,
      });
    },

    checkOutBooking() {
      this.$emit("onCheckOutBooking", {
        booking: this.booking,
        invoice: this.salesInvoice,
      });
    },

    async cancelBooking() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      const that = this;

      const cancelInformations = await this.$store.dispatch(
                                  "bookings/cancelInformations",
                                  that.booking.id
                                );

      swal.close();

      let swalConfig = {
        title: this.$t("BOOKINGS.CANCEL_THIS_BOOKING"),
        type: "question",
        html: "",
        customClass: {
          popup: "default-popup warning",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",

        showLoaderOnConfirm: () => that.isLoadingCancel,

        willClose: () => {
          
        },

        preConfirm : async () => {
          // Action à exécuter lorsque l'utilisateur clique sur "Oui"
         // Swal.showLoading();

          try {
            that.isLoadingCancel = true;
            
            await that.$store.dispatch("bookings/cancel", that.booking.id);
            that.booking = await that.$store.getters["bookings/booking"];

            that.$notify({
                  type: "success",
                  message: this.$t("BOOKINGS.BOOKING_CANCELLED"),
                });

          //  console.log("this.processToUpdate => ", this.updatedInvoice);

            that.$emit("onBookingCancelled", that.booking);
            swal.close();
          } catch (error) {
            console.error("ViewBooking::Cancel => ", error);

            this.$notify({
              type: "danger",
              message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
            });
          } finally {
            this.isLoadingCancel = false;
            swal.hideLoading();
          }
        },
      };

      if (cancelInformations.fees.total > 0) {
        const policyPage = cancelInformations.policy ? this.$router.resolve({
          name: "Policy Page",
          query: { id: cancelInformations.policy.id, action: QUERY_ACTIONS_VIEW },
        }).href : "";
        swalConfig.html = `
          <span>
            ${this.$t("BOOKINGS.CANCELLATION_WITH_FEES", [policyPage])}
          </span><br/><br/>
        `;

        // affichage des frais d'annulation
        swalConfig.html += `
          <span>
            ${this.$t("BOOKINGS.CANCEL_FEE", [
              this.$formatCurrency(cancelInformations.fees.total),
            ])}
          </span><br/><br/>
        `;
      }

      if (cancelInformations.refund_amount) {
        if (cancelInformations.refund_amount > 0) {
          swalConfig.html += `
            <span>
              ${this.$t("BOOKINGS.REFUND_AMOUNT", [
                this.$formatCurrency(cancelInformations.refund_amount),
              ])}
            </span><br/>`;
        } else {
          swalConfig.html += `
            <span>
              ${this.$t("BOOKINGS.BALANCE_OUTSTANDING_AMOUNT", [
                this.$formatCurrency(cancelInformations.refund_amount),
              ])}
            </span><br/><br/>`;
        }
      }

      const confirmation = await swal.fire(swalConfig);
      if (confirmation.isConfirmed) {
       // this.$emit("onCancellingBooking", {
        //  booking : this.booking,
         // cancelInformations,
        //});
      }
      return;
    },

    onEditBooking() {
      this.$emit("onEditBooking", this.booking);
    },

    getFilesObject() {
      return {
        ...cloneDeep(this.booking.organization),
        organization: cloneDeep(this.booking.organization),
      };
    },
  },
};
</script>
