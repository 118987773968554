<template>
  <div class="tab-container">
    <policies-body-list :type="types.COMMUNICATION" />
  </div>
</template>

<script>
import PoliciesBodyList from "@/views/Pages/PolicyModule/PolicyManagement/components/PoliciesBodyList.vue";
import { PoliciesTypes } from "@/constants/policies";

export default {
  name: "policies-view-communication",

  components: {
    PoliciesBodyList,
  },
  props: {},
  data() {
    return {
      types: PoliciesTypes,
    };
  },
  async mounted() {},
  async updated() {
    // this.fetchPolicies();
  },

  computed: {},

  methods: {},
};
</script>
<style></style>
