<template>
  <div class="confirmation-with-payment">
    <credit-card-selector
      :customer-id="orderData.recipient.id"
      v-model="selectedCreditCard"
      @creditCardChanged="(creditCard) => (selectedCreditCard = creditCard)"
    />

    <div>
      <b>Montant du paiement :</b> <span style="color: var(--primary) !important; font-weight: bold">{{ $formatCurrency(amount) }}</span>
    </div>

    <!-- comment -->
    <div class="form-wrapper full">
      <base-input :label="$t('BOOKINGS.INTERNAL_NOTE')">
        <el-input type="textarea" v-model="order.excerpt"></el-input>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div v-if="selectedCreditCard" class="buttons-group">
      <el-button
        class="continue"
        type="primary"
        @click="handleSubmit"
        :loading="loading"
      >
        {{ $t("COMMON.CONFIRM") }}
      </el-button>
    </div>
    <ButtonViewPolicies
     :label="'Consulter les politiques de cette réservation'"
    />
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Button, DatePicker, Image, Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError";
import CreditCardSelector from "@/components/CreditCardSelector";
import swal from "sweetalert2";
import ButtonViewPolicies from "@/views/Pages/PolicyModule/PolicyManagement/components/ButtonViewPolicies.vue";



export default {
  name: "booking-confirmation-with-payment",

  components: {
    ValidationError,
    CreditCardSelector,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    ButtonViewPolicies,
  },

  mixins: [formMixin],

  props: {
    orderData: {
      type: Object,
      description: "Sales order object",
    },
  },

  data() {
    return {
      order: this.orderData,
      selectedCreditCard: null,
      showModal: false,
      showBookingCheckModal: false,
      loading: false,
    };
  },

  created() {},

  mounted() {},

  computed: {
    amount: function () {
      return this.order.itemsBookings[0]?.salesOrderable?.pricing_data?.payment_plan?.at_booking;
    }
  },

  methods: {
    async handleSubmit() {
      const confirmation = await swal.fire({
        title: this.$t("BOOKINGS.CONFIRM_BOOKING"),
        html: this.$t("BOOKINGS.CONFIRM_THIS_BOOKING", {
          email: this.order.email,
        }),
        type: "question",
        customClass: {
          popup: "default-popup warning",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.isConfirmed && (await this.performPayment())) {
        this.$emit("onSubmitted");
      }
    },

    async performPayment() {
      this.loading = true;

      let data = {
        credit_card_id: this.selectedCreditCard,
      };

      try {
        await this.$store.dispatch("salesOrders/charge", {
          id: this.orderData.id,
          data: data,
        });
        return true;
      } catch (error) {
        this.setApiValidation(error.response.data.errors);

        let notificationOptions = {
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        };

        if (error.response.data.errors[0]?.detail) {
          notificationOptions.title = this.$t("ERRORS.SOMETHING_WENT_WRONG");
          notificationOptions.message = error.response.data.errors[0]?.detail;
        }
        this.$notify(notificationOptions);
      }

      this.loading = false;

      return false;
    },

    onFormChanged() {
      this.$emit("onFormChanged", this.booking);
    },
  },

  watch: {
    orderData: {
      handler: function (orderData) {
        if (orderData) {
          this.order = {
            ...this.order,
            ...cloneDeep(orderData),
          };
        }
      },
      deep: true,
    },
  },
};
</script>
