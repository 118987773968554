<template>
  <div class="container">
    <!-- Loader -->
    <span class="resize-loading" v-if="isLoadingPreview">
      <span class="loader"></span>
    </span>

    <div class="resume" v-if="updatedInvoice">
      <div v-for="(item, i) in orderedItems" :key="i">
        <div>
          {{ getItemLabel(item) }}
        </div>
        <div>
          <strong>{{ $formatCurrency(item.pricing.taxable_amount) }}</strong>
        </div>
      </div>

      <div>
        <div>
          {{ $t("COMMON.SUBTOTAL") }} :
          <strong>{{ $formatCurrency(subtotal) }}</strong>
        </div>
        <div v-if="taxes">
          <div v-for="tax in taxes" :key="tax.name">
            {{ tax.name }} <strong>{{ $formatCurrency(tax.amount) }}</strong>
          </div>
        </div>
        <div class="text-black">
          Total: <strong>{{ $formatCurrency(total) }}</strong>
        </div>

        <div class="text-red">
          {{ $t("COMMON.ALREADY_PAID") }} :
          <strong>{{ $formatCurrency(updatedInvoice.total_paied) }}</strong>
        </div>

        <div :class="isRefund ? 'text-red' : 'text-green'">
          {{ isRefund ? "Remboursement" : "Solde à Payer" }}
          <strong>{{ $formatCurrency(Math.abs(total_difference)) }}</strong>
        </div>
      </div>

      <!--<div class="form-wrapper full" v-if="balance > 0">
        <credit-card-selector
            :customer-id="payload.customer"
            v-model="payload.card"
            @creditCardChanged=" (creditCardId) => payload.card = creditCardId"
        />
        <validation-error :errors="apiValidationErrors.excerpt"/>
      </div>-->
    </div>

    <!-- region action button -->
    <div class="buttons">
      <el-button
        class="cancel"
        type="default"
        :disabled="isLoadingPreview || isLoadingUpdate"
        :loading="isLoadingPreview || isLoadingUpdate"
        @click="handlePreviewCanceled"
      >
        {{ $t("COMMON.CANCEL") }}
      </el-button>

      <el-button
        class="submit"
        type="primary"
        @click="processToUpdate"
        :loading="isLoadingPreview || isLoadingUpdate"
        :disabled="isLoadingPreview || !updatedInvoice || isLoadingUpdate"
      >
        {{ $t("COMMON.CONFIRM") }}
      </el-button>
    </div>
    <!-- endregion -->
  </div>
</template>

<script>
import { Button } from "element-ui";
import _ from "lodash";
import CreditCardSelector from "@/components/CreditCardSelector.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import Swal from "sweetalert2";

export default {
  name: "BookingUpdatePreview",
  components: {
    ValidationError,
    [Button.name]: Button,
    CreditCardSelector,
  },

  props: {
    invoiceId: {
      type: Number,
      required: true,
    },
    originalBooking: {
      type: Object,
      required: true,
    },
    newBooking: {
      type: Object,
      required: true,
    },
  },
  mixins: [formMixin],

  data() {
    return {
      isLoadingPreview: false,
      isLoadingUpdate: false,
      updatedInvoice: null,
      payload: {
        newBooking: null,
        customer: null,
        card: null,
        do_pay_balance: true,
      },
    };
  },

  created() {
    this.loadPreviewUpdate(this.invoiceId);
  },

  watch: {
    isLoadingPreview(newVal) {
      this.$emit("onPreviewed", newVal);
    },
    updatedInvoice(newVal) {
      if (newVal) {
        this.payload.newBooking = this.pickedBooking;
        this.payload.customer = newVal.recipient.id;
      }
    },
  },

  computed: {
    items() {
      return this.updatedInvoice?.items ?? [];
    },
    orderedItems() {
      if (!this.items) return [];

      let that = this;

      return this.items.sort(function (a, b) {
        if (!that.itemHasSpot(a) && that.itemHasSpot(b)) return 1; // Si `itemHasSpot(a,orderType)` est vide, place `b` avant.
        if (that.itemHasSpot(a) && !that.itemHasSpot(b)) return -1; // Si `b.name` est vide, place `a` avant.
        return 0; // Si les deux sont vides ou les deux sont remplis, conserve l'ordre actuel.
      });
    },

    taxes() {
      return Object.values(this.updatedInvoice?.pricing?.taxes?.details ?? []);
    },

    discounts() {
      return this.invoice?.pricing?.discounts ?? [];
    },
    subtotal() {
      return this.updatedInvoice?.pricing?.subtotal ?? 0;
    },
    total() {
      return this.updatedInvoice?.pricing?.total ?? 0;
    },
    total_difference() {
      return this.updatedInvoice?.pricing_updated?.total_difference ?? 0;
    },
    isRefund() {
      return this.total_difference <= 0;
    },

    pickedBooking() {
      const booking = this.newBooking;
      booking.from_booking_id = this.originalBooking.id;

      return _.pick(booking, [
        "equipment",
        "passenger_extensions",
        "start_at",
        "end_at",
        "adults_count",
        "children_count",
        "pets_count",
        "equipment_length",
        "driver_extensions",
        "special_request",
        "comment",
        "rooms_qty",
        "beds_qty",
        "services_included",
        "electricity",
        "pricing",
        "pricing_custom",
        "from_booking_id",
        "spot_id",
      ]);
    },
  },

  methods: {
    async processToUpdate() {
      Swal.fire({
        title: this.$t("BOOKINGS.CONFIRM_UPDATE_BOOKING"),
        type: "question",
        html: this.$t("BOOKINGS.CONFIRM_UPDATE_BOOKING_WARNING"),
        customClass: {
          popup: "default-popup warning",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showLoaderOnConfirm: () => this.isLoadingUpdate,

        willClose: () => {},

        preConfirm: async () => {
          // Action à exécuter lorsque l'utilisateur clique sur "Oui"
          // Swal.showLoading();

          try {
            this.isLoadingUpdate = true;
            const invoiceId = this.invoiceId;
            const newBooking = this.pickedBooking;
            const { card, customer } = this.payload;

            const data = {
              invoiceId,
              newBooking,
              card,
              customer,
            };

            this.updatedInvoice = await this.$store.dispatch(
              "salesInvoices/processToUpdate",
              data
            );

            this.$notify({
              type: "success",
              message: this.$t("BOOKINGS.BOOKING_UPDATED"),
            });

            //  console.log("this.processToUpdate => ", this.updatedInvoice);

            this.$emit("onUpdatedSuccess", this.updatedInvoice);
            Swal.close();
          } catch (error) {
            console.error("BookingUpdatePreview::loadPreviewUpdate => ", error);

            this.$notify({
              type: "danger",
              message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
            });
          } finally {
            this.isLoadingUpdate = false;
            Swal.hideLoading();
          }
        },
      });
    },

    async loadPreviewUpdate() {
      try {
        this.isLoadingPreview = true;
        const invoiceId = this.invoiceId;
        const payload = this.payload;
        payload.newBooking = this.pickedBooking;

        payload.newBooking.from_booking_id = this.originalBooking.id;

        console.log("loadPreviewUpdatenewBooking => ", payload.newBooking);

        console.log("{invoiceId, payload}: ", { invoiceId, payload });

        this.updatedInvoice = await this.$store.dispatch(
          "salesInvoices/previewFromUpdatedBooking",
          { invoiceId, payload }
        );

        this.$emit("onInvoicePreviewed", this.updatedInvoice?.preview_details);
        //this.updatedInvoice = invoice;
        //console.log("invoice => ", invoice);
        //console.log("details => ", details);
      } catch (error) {
        console.error("booking.loadPreviewUpdate => ", error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.isLoadingPreview = false;
      }
    },

    handlePreviewCanceled() {
      this.$emit("onPreviewCanceled");
    },

    itemHasSpot(item) {
      return item.salesInvoiceable?.spot;
    },

    getItemLabel(item) {
      return this.itemHasSpot(item)
        ? `${item.salesInvoiceable.spot.name} - ${item.salesInvoiceable.spot.code}`
        : item?.excerpt ?? "Item Label";
    },
  },
};
</script>
