<template>
  <div class="flexs">
    <h2 class="font-semibold">{{ rank }} - {{ policyData.name }}</h2>

    <ol class="mb-2 ml-4" style="list-style: lower-alpha">
      <li class="mb-3" v-if="!isEmptyContent(policyData.description)">
        <h3 class="font-semibold">Description de la politique</h3>
        <p
          class="text-gray-500 text-xs ml-2"
          v-html="policyData.description"
        ></p>
      </li>

      <li class="mb-3" v-if="pricingRules.length">
        <h4 class="font-semibold">Tarifs associés</h4>
        <ul class="has-circle ml-2" style="list-style: circle">
          <li v-for="rule in pricingRules" :key="rule.id">
            {{ rule.name }}
          </li>
        </ul>
      </li>

      <li class="mb-3" v-if="spotTypes.length">
        <h4 class="font-semibold">Emplacements concernés</h4>
        <ul class="has-circle ml-2" style="list-style: circle">
          <li v-for="type in spotTypes" :key="type.id">
            {{ type.name }}
          </li>
        </ul>
      </li>

      <li class="mb-3" v-if="rules.length">
        <h4 class="font-semibold">Emplacements & Tarifs associés</h4>
        <ul class="has-circle ml-2" style="list-style: circle">
          <li v-for="(rule,i) in rules" :key="i" v-html="rule"> </li>
        </ul>
      </li>

      <li class="mb-3" v-if="details.length">
        <h4 class="font-semibold">Détails</h4>
        <ul class="has-circle ml-2" style="list-style: circle">
          <li class="mb-3" v-for="(detail,i) in details" :key="i">
            <h5 class="font-semibold" v-html="detail.title"></h5>
              <ul 
                  class="has-circle ml-2" 
                  style="list-style: circle" 
                  v-if="detail.list"
                >
                <li v-for="(item,i) in detail.list" :key="i" v-html="item"> </li>
              </ul>
          </li>
        </ul>
      </li>

      <li class="mb-2" v-if="policyData.payment_policies_hours">
        <h4 class="font-semibold">Détails et frais d'annulation</h4>
        <p class="ml-2">
          {{ title }}
          <span>{{ policyData.payment_policies_hours }} {{ "heure(s)" }}</span>
          <span>{{ ` ${type?.name}` }}</span>
          <strong>
            <span class="ml-1 fa fa-arrow-right" />
            {{ `${policyData.payment_value} ${fee?.abbr}` }}
          </strong>
          <span>{{ `${fee?.desc}` }}</span>
        </p>
      </li>
    </ol>
  </div>
</template>

<script>
import {
  PoliciesTypes,
  PaymentFrequenciesList,
  PaymentsTypesList,
} from "@/constants/policies";

export default {
  name: "policy-content",
  components: {},
  props: {
    policy: {
      type: Object,
      required: true,
    },
    rank: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      open: false, // Dialog ouvert ou fermé
      policyData: this.policy,
    };
  },
  computed: {
    type() {
      return PaymentFrequenciesList.find(
        (p) => p.code == this.policy.payment_policies
      );
    },
    fee() {
      return PaymentsTypesList.find(
        (fee) => fee.code === this.policy.payment_type
      );
    },
    pricingRules() {
      return this.policy?.pricingRules ?? [];
    },
    spotTypes() {
      return this.policy?.spotTypes ?? [];
    },

    title() {
      let text = null;

      if (this.policy.policies_type == PoliciesTypes.CANCELLATION) {
        text = "Annulation effectuée";
      } else if (this.policy.policies_type == PoliciesTypes.PAYMENTS) {
        text = "Payment effectuée";
      }

      return text;
    },

    rules() {
      return this.policy?.rules ?? [];
    },

    details() {
      return this.policy?.details ?? [];
    },
  },
  methods: {
    /**
     * Supprimer les balises HTML et espaces
     * @param {String} content
     */
    strippedContent(content) {
      return content.replace(/<[^>]*>/g, "").trim();
    },
    /**
     * Vérifier si le contenu est vide
     * @param {String} content
     */
    isEmptyContent(content) {
      if (!content) return true; // Si null ou undefined

      return this.strippedContent(content) === ""; // Retourne true si vide
    },
  },
};
</script>
