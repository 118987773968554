<template>
  <div class="bryntum-calendar-wrapper">
    <div class="dates-range">
      <div class="dates-range-left">
        <base-button class="colored" @click="goTo(-30)">
          <i class="far fa-angle-double-left"></i> 30
        </base-button>
        <base-button class="outline" @click="goTo(-7)">
          <i class="far fa-angle-double-left"></i> 7
        </base-button>
      </div>
      <div class="dates-range-center">
        <base-button class="outline" @click="today">
          <i class="far fa-angle-double-right"></i>
          {{ $t("COMMON.TODAY") }}
          <i class="far fa-angle-double-left"></i>
        </base-button>
      </div>
      <div class="dates-range-right">
        <base-button class="outline" @click="goTo(7)">
          7 <i class="far fa-angle-double-right"></i>
        </base-button>
        <base-button class="colored" @click="goTo(30)">
          30 <i class="far fa-angle-double-right"></i>
        </base-button>
      </div>
    </div>
    <bryntum-scheduler
      v-bind="schedulerConfig"
      :events="events"
      :resources="resources"
      :end-date="endDate"
      event-style="rounded"
      :start-date="startDate"
      :scheduleMenuFeature="true"
      :cellEditFeature="false"
      :cellMenuFeature="false"
      :eventMenuFeature="false"
      :timeRangesFeature="true"
      :timeRanges="timeRanges"
      :key="renderKey"
    />
    <!-- region Legends -->
    <div class="legend">
      <h3>{{ $t("COMMON.LEGENDS") }}:</h3>
      <div class="legend-items">
        <ul class="legend-item pending">
          <li class="circle"></li>
          <li>{{ $t("BOOKINGS.DRAFT") }}</li>
        </ul>
        <ul class="legend-item guaranteed">
          <li class="circle"></li>
          <li>{{ $t("BOOKINGS.GUARANTEED_RESERVATION") }}</li>
        </ul>
        <ul class="legend-item not-guaranteed">
          <li class="circle"></li>
          <li>{{ $t("BOOKINGS.NOT_GUARANTEED_RESERVATION") }}</li>
        </ul>
        <ul class="legend-item arrived">
          <li class="circle"></li>
          <li>{{ $t("COMMON.ARRIVED") }}</li>
        </ul>
        <ul class="legend-item left">
          <li class="circle"></li>
          <li>{{ $t("COMMON.LEFT") }}</li>
        </ul>
        <ul class="legend-item balance-due">
          <li class="circle"></li>
          <li>{{ $t("COMMON.BALANCE_DUE") }}</li>
        </ul>
        <ul class="legend-item blocked">
          <li class="circle"></li>
          <li>{{ $t("COMMON.BLOCKED") }}</li>
        </ul>
      </div>
    </div>
    <!-- endregion -->
  </div>
</template>

<script>
import { BryntumScheduler } from "@bryntum/scheduler-vue";
import { StringHelper, DateHelper } from "@bryntum/scheduler";
import { CONDITION_CLEAN } from "@/constants/spots";
import moment from "moment";
import {
  BOOKING_STATUS_DRAFT,
  BOOKING_STATUS_OUT_OF_SERVICE,
  BOOKING_STATUS_FINISHED,
  BOOKING_STATUS_ARRIVED,
  BOOKING_STATUS_GUARANTEED,
  BOOKING_STATUS_NOT_GUARANTEED,
  BOOKING_STATUS,
  SPOTS_CONDITIONS,
} from "@/constants/common";

import { STATUS_BLOCKED } from "@/constants/bookings";

export default {
  name: "calendar-component",

  components: { BryntumScheduler },

  props: {
    spots: {
      type: Array,
      required: true,
    },
    filterBookingStatus: {
      type: String,
      default: null,
      required: false,
    },
    filterBookingIsBlocked: {
      type: String,
      default: null,
      required: false,
    },
    filterSpotsCondition: {
      type: String,
      default: null,
      required: false,
    },
    filterCalendarDate: {
      type: Number,
      required: false,
      description:
        "Filter le calendrier sur un mois de l'année courante, 1-12, au dela de 12 pour avancer d'un certain nombre de jours à partir d’aujourd’hui.",
    },
    filterSearchBooking: {
      type: String,
      required: false,
      description:
        "Un mot clé pour rechercher la réservation d'un client avec son nom, tel, email et code de réservation.",
    },
  },

  data() {
    let currentDate = moment().format("YYYY-MM-DD");
    let _startDate = DateHelper.add(currentDate, -5, "days");
    let _endDate = DateHelper.add(currentDate, 30, "days");

    if (
      this.filterCalendarDate &&
      this.filterCalendarDate >= 1 &&
      this.filterCalendarDate <= 12
    ) {
      _startDate = moment()
        .month(this.filterCalendarDate - 1)
        .startOf("month")
        .format("YYYY-MM-DD");
      _endDate = moment()
        .month(this.filterCalendarDate - 1)
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (this.filterCalendarDate > 12) {
      // move forward
      _startDate = DateHelper.add(_startDate, this.filterCalendarDate, "days");
      _endDate = DateHelper.add(_endDate, this.filterCalendarDate, "days");
    }

    return {
      CONDITION_CLEAN,
      selectedEventRecord: {},
      startDate: _startDate,
      endDate: _endDate,
      renderKey: 1,
      selectedOrderId: null,
      isToday: false,
      spotLockingDuration: this.spots?.length
        ? this.spots[0].locked_in_draft_duration_in_min
        : 15,
    };
  },

  computed: {
    currentDate: function () {
      return moment().format("YYYY-MM-DD");
    },
    resources: function () {
      if (!this.spots || !this.spots.length) {
        return [];
      }

      return this.spots.map((spot) => ({
        id: spot.id,
        name: spot.code,
        realName: spot.name,
        clean_condition: spot.clean_condition,
        clean: spot.clean_condition === this.CONDITION_CLEAN,
        locked_in_draft: spot.locked_in_draft,
        current_bookings_date: spot.bookings.map((booking) => {
          return {
            start_at: moment(`${booking.start_at.split("T")[0]} ${spot.arrival_time}`),
            end_at: moment(`${booking.end_at.split("T")[0]} ${spot.departure_time}`),
          }
        }),
      }));
    },
    timeRanges: function () {
      const timeRanges = [];

      if (this.startDate < new Date()) {
        timeRanges.push({
          id: 1,
          cls: "shaded",
          startDate: this.startDate,
          endDate: new Date(),
        });
      }

      return timeRanges;
    },
    events: function () {
      let events = [];

      if (!this.spots || !this.spots.length) {
        return events;
      }

      this.spots.forEach((spot) => {
        let bookings = spot.bookings;
        events = [
          ...events,
          ...bookings.map((booking) => {
            const personCapacity =
              Number(booking.adults_count ?? 0) +
              Number(booking.children_count ?? 0);

            let cls = "";
            let status = booking.status;
            let iconCls = "fas fa-truck";
            let customer = null;
            let resizable = false;

            if (booking.status === BOOKING_STATUS_OUT_OF_SERVICE) {
              cls = "out-of-service";
              iconCls = null;
            } else {
              switch (booking.status) {
                case BOOKING_STATUS_NOT_GUARANTEED:
                  cls = "not-guaranteed";
                  break;
                case BOOKING_STATUS_GUARANTEED:
                  cls = "guaranteed";
                  break;
                case BOOKING_STATUS_DRAFT:
                  cls = "pending";
                  break;
                case BOOKING_STATUS_ARRIVED:
                  cls = "arrived";
                  break;
                case BOOKING_STATUS_FINISHED:
                  cls = "left";
                  break;
              }

              customer = !!booking.customer
                ? `${
                    booking.customer.firstname
                  } ${booking.customer.lastname[0].toUpperCase()}.`
                : null;
            }

            if (
              booking.is_spot_blocked &&
              ![
                BOOKING_STATUS_FINISHED,
                BOOKING_STATUS_OUT_OF_SERVICE,
              ].includes(booking.status)
            ) {
              cls = "blocked";
            }

            const bookingDays = moment(booking.end_at).diff(
              booking.start_at,
              "days"
            );

            return {
              id: booking.id,
              code: booking.code,
              resourceId: spot.id,
              spotId: spot.id,
              startDate: moment(booking.start_at.split("T")[0])
                .set({
                  hour: (spot.arrival_time??'15:00').split(":")[0],
                  minute: (spot.arrival_time??'15:00').split(":")[1],
                })
                .toDate(),
              endDate: moment(booking.end_at.split("T")[0])
                .set({
                  hour: (spot.departure_time??'12:00').split(":")[0],
                  minute: (spot.departure_time??'12:00').split(":")[1],
                })
                .toDate(),
              invoiceId: booking?.invoice_id,
              iconCls,
              personCapacity,
              status,
              cls,
              bookingDays,
              customer,
              reason: booking.out_of_service_reason,
              resizable,
              draggable: false,
            };
          }),
        ];
      });

      return events;
    },
    schedulerConfig: function () {
      return {
        viewPreset: {
          cls: "view-preset",
          base: "dayAndMonth",
          timeResolution: {
            unit: "day",
            increment: 1,
          },
        },
        scheduleTooltipFeature: false,
        rowHeight: 40,
        barMargin: 5,
        tickSize: 40,
        zoomOnTimeAxisDoubleClick: false,
        zoomOnMouseWheel: false,
        getDateConstraints(resourceRecord, eventRecord) {
          return {
            start: new Date(),
          };
        },
        eventEditFeature: {
          editorConfig: {
            header: false,
            autoHide: false,
            flex: "col",
            cls: "view-event",
            items: {
              nameField: null,
              resourceField: null,
              startDateField: null,
              startTimeField: null,
              endDateField: null,
              endTimeField: null,
              container: {
                type: "container",
                items: {
                  selectedPeriodSection: {
                    type: "container",
                    items: {
                      selectedPeriodLabel: {
                        type: "label",
                        cls: "font-weight-bold",
                        text: this.$t("BOOKINGS.SELECTED_PERIOD"),
                      },
                      selectedPeriodValue: {
                        type: "label",
                      },
                    },
                  },
                  customerSection: {
                    type: "container",
                    items: {
                      customerIcon: {
                        type: "label",
                        html: '<i class="fas fa-user"></i>',
                      },
                      customerName: {
                        type: "label",
                        cls: "font-weight-bold",
                      },
                    },
                  },
                  statusSection: {
                    type: "container",
                    items: {
                      statusLabel: {
                        type: "label",
                        cls: "font-weight-bold",
                        text: this.$t("COMMON.STATUS"),
                      },
                      statusValue: {
                        type: "label",
                      },
                    },
                  },
                  reasonSection: {
                    type: "container",
                    items: {
                      reasonLabel: {
                        type: "label",
                        cls: "font-weight-bold",
                        text: this.$t("COMMON.REASON"),
                      },
                      reasonValue: {
                        type: "label",
                      },
                    },
                  },
                  bookingDaysSection: {
                    type: "container",
                    items: {
                      bookingDaysLabel: {
                        type: "label",
                        cls: "font-weight-bold",
                        text: this.$t("BOOKINGS.BOOKING_DAYS"),
                      },
                      bookingDaysValue: {
                        type: "label",
                      },
                    },
                  },
                },
              },
            },
            bbar: {
              items: {
                saveButton: null,
                deleteButton: null,
                cancelButton: null,
                showDetailButton: {
                  type: "button",
                  cls: "btn btn-warning bg-warning text-white show-details",
                  text: this.$t("COMMON.SHOW_DETAILS"),
                  onAction: this.viewBooking,
                },
                showPayButton: {
                  type: "button",
                  cls: "btn btn-warning bg-warning text-white show-details",
                  text: this.$t("SALES_INVOICES.PAY"),
                  onAction: this.payBooking,
                },
                editBookingButton: {
                  type: "button",
                  cls: "btn btn-warning bg-warning text-white new-button",
                  text: this.$t("BOOKINGS.EDIT_BOOKING"),
                  onAction: this.editBooking,
                },
                checkInButton: {
                  type: "button",
                  cls: "btn btn-warning bg-warning text-white out-service",
                  text: this.$t("BOOKINGS.CHECK_IN"),
                  onAction: this.checkInBooking,
                },
                checkOutButton: {
                  type: "button",
                  cls: "btn btn-warning bg-warning text-white out-service",
                  text: this.$t("BOOKINGS.CHECK_OUT"),
                  onAction: this.checkOutBooking,
                },
                newBookingButton: {
                  type: "button",
                  cls: "btn btn-warning bg-warning text-white new-button",
                  text: this.$t("COMMON.NEW_BOOKING"),
                  onAction: this.addBooking,
                },
                backIntoServiceButton: {
                  type: "button",
                  cls: "btn btn-warning bg-warning text-white put-back",
                  text: this.$t("COMMON.PUT_BACK_INTO_SERVICE"),
                  onAction: this.putBackIntoService,
                },
                outOfServiceButton: {
                  type: "button",
                  cls: "btn btn-warning bg-warning text-white out-service",
                  text: this.$t("COMMON.PUT_OUT_OF_SERVICE"),
                  onAction: this.putOutOfService,
                },
                showLockedSpotWarning: {
                  type: "label",
                  text: this.$t("SPOTS.BLOCKED_IN_SPOT", {
                    duration: this.spotLockingDuration,
                  }),
                  cls: "long-text",
                },
              },
            },
          },
        },
        listeners: {
          beforeEventEditShow: this.beforeEventEditShow,
          beforeEventResizeFinalize: this.beforeEventResizeFinalize,
        },
        eventTooltipFeature: {
          template: (data) => {
            let booking = data.eventRecord.data;
            let status = booking.status;
            if (status === BOOKING_STATUS_OUT_OF_SERVICE) {
              return this.$t(`BOOKINGS.${status}`);
            }

            return `${this.$t("BOOKINGS.BOOKING_IDENTIFIER", {
              code: data.eventRecord.data.code,
            })} [${this.$t(`BOOKINGS.${booking.status}`)}] : ${
              booking.customer ?? "N/A"
            } `;
          },
        },

        columns: [
          {
            text: this.$t("COMMON.SPOTS"),
            field: "name",
            width: 150,
            htmlEncode: false,
            renderer: ({ value, record }) => {
              let realName = record.data.realName;
              return StringHelper.xss`<div title="${realName}" class="w-100 d-flex justify-content-between align-items-center">
                                        <span>${StringHelper.encodeHtml(
                                          value
                                        )}</span>
                                      </div>`;
            },
          },
          {
            type: "action",
            align: "start",
            actions: [
              {
                renderer: ({ record }) =>
                  `<span class="${
                    record.data.clean ? "clean-round" : "dirty-round"
                  }"> </span>`,

                // tooltip: ({ record }) =>
                //   `<p class="b-nicer-than-default">Add to ${record.name}</p>`,
                onClick: this.cleanSpot,
              },
            ],
          },
        ],

        columnLines: false,

        eventRenderer: ({ eventRecord }) => {
          if (eventRecord.status === BOOKING_STATUS_OUT_OF_SERVICE) {
            let status = this.$t(`BOOKINGS.${eventRecord.status}`);
            return StringHelper.xss`<span class="text-white ml-2">${status}</span>`;
          }

          let codeLabel = this.$t("BOOKINGS.BOOKING_IDENTIFIER", {
            code: eventRecord.code,
          });
          return StringHelper.xss`
                <span title="${codeLabel}" class="text-white mr-2">
                    ${eventRecord.customer ?? ""}
                </span>
                <span title="${codeLabel}" class="d-inline-flex justify-content-center align-items-center">
                    <span class="user"></span>
                    <small class="${
                      eventRecord.personCapacity ? "" : "d-none"
                    }">
                        ${eventRecord.personCapacity}
                    </small>
                </span>
                <span title="${codeLabel}" class="text-white ml-2">
                    #${eventRecord.code}
                </span>`;
        },
      };
    },
  },

  methods: {
    async beforeEventEditShow({ editor, eventRecord, resourceRecord }) {
      const newBookingButton = editor.widgetMap.bbar.widgetMap.newBookingButton;
      const editBookingButton =
        editor.widgetMap.bbar.widgetMap.editBookingButton;
      const outOfServiceButton =
        editor.widgetMap.bbar.widgetMap.outOfServiceButton;
      const showDetailButton = editor.widgetMap.bbar.widgetMap.showDetailButton;
      const backIntoServiceButton =
        editor.widgetMap.bbar.widgetMap.backIntoServiceButton;
      const showLockedSpotWarning =
        editor.widgetMap.bbar.widgetMap.showLockedSpotWarning;
      const checkInButton = editor.widgetMap.bbar.widgetMap.checkInButton;
      const checkOutButton = editor.widgetMap.bbar.widgetMap.checkOutButton;
      const showPayButton = editor.widgetMap.bbar.widgetMap.showPayButton;

      const container = editor.widgetMap.container;

      const selectedPeriodSection = container.widgetMap.selectedPeriodSection;
      const selectedPeriodValue = container.widgetMap.selectedPeriodValue;

      const customerSection = container.widgetMap.customerSection;
      const customerName = container.widgetMap.customerName;

      const statusSection = container.widgetMap.statusSection;
      const statusValue = container.widgetMap.statusValue;

      const bookingDaysSection = container.widgetMap.bookingDaysSection;
      const bookingDaysValue = container.widgetMap.bookingDaysValue;

      const reasonSection = container.widgetMap.reasonSection;
      const reasonValue = container.widgetMap.reasonValue;

      selectedPeriodSection.hidden = true;
      customerSection.hidden = true;
      statusSection.hidden = true;
      bookingDaysSection.hidden = true;
      reasonSection.hidden = true;
      showPayButton.hidden = true;

      newBookingButton.hidden = true;
      editBookingButton.hidden = true;
      outOfServiceButton.hidden = true;
      showDetailButton.hidden = true;
      backIntoServiceButton.hidden = true;
      showLockedSpotWarning.hidden = true;
      checkInButton.hidden = true;
      checkOutButton.hidden = true;

      this.selectedEventRecord = {
        ...eventRecord,
        resourceData: resourceRecord.data,
      };

      console.log("eventRecord: ", this.selectedEventRecord.status);
      console.log("eventRecord.customer: ", this.selectedEventRecord.customer);
      console.log(
        "eventRecord.invoiceId: ",
        this.selectedEventRecord.invoiceId
      );

      if (
        eventRecord.name === "New event" ||
        eventRecord.status === BOOKING_STATUS_OUT_OF_SERVICE
      ) {
        // if(eventRecord.locked_in_draft)
        let mStartDate = moment(eventRecord.startDate);
        let mEndDate = moment(eventRecord.endDate);

        // let resource = this.getResource(eventRecord.data.resourceId);
        let resource = resourceRecord.data;
        await this.$store.dispatch("spots/getSpotCartStatus", resource.id);
        resource.locked_in_draft =
          this.$store.getters["spots/getSpotCartStatus"];

        if (
          this.isOverlappingOnBookings(
            mStartDate,
            mEndDate,
            resource.current_bookings_date
          ) &&
          resource.locked_in_draft
        ) {
          showLockedSpotWarning.hidden = false;
          return;
        }

        selectedPeriodSection.hidden = false;
        if (eventRecord.status === BOOKING_STATUS_OUT_OF_SERVICE) {
          if (moment().diff(eventRecord.endDate) < 0) {
            backIntoServiceButton.hidden = false;
          }

          reasonSection.hidden = false;
          reasonValue.text = this.$t(`BOOKINGS.REASON_${eventRecord.reason}`)
            ? this.$t(`BOOKINGS.REASON_${eventRecord.reason}`)
            : eventRecord.reason;
        }

        if (eventRecord.name === "New event") {
          newBookingButton.hidden = false;
          outOfServiceButton.hidden = false;
        }

        selectedPeriodValue.text = `${DateHelper.format(
          eventRecord.startDate,
          "DD MMM"
        )} - ${DateHelper.format(eventRecord.endDate, "DD MMM")}`;
      } else {
        showDetailButton.hidden = false;
        customerSection.hidden = false;
        statusSection.hidden = false;
        bookingDaysSection.hidden = false;

        switch (eventRecord.status) {
          case BOOKING_STATUS_FINISHED:
            checkInButton.hidden = true;
            checkOutButton.hidden = true;
            editBookingButton.hidden = true;
            break;
          case BOOKING_STATUS_ARRIVED:
            checkInButton.hidden = true;
            checkOutButton.hidden = false;
            editBookingButton.hidden = false;
            break;
          case BOOKING_STATUS_NOT_GUARANTEED:
          case BOOKING_STATUS_GUARANTEED:
            checkInButton.hidden = false;
            editBookingButton.hidden = false;
            break;
          case BOOKING_STATUS_DRAFT:
            editBookingButton.hidden = false;
            break;
        }

        if (
          eventRecord.status === BOOKING_STATUS_DRAFT &&
          eventRecord.customer &&
          !eventRecord.invoiceId
        ) {
          showPayButton.hidden = false;
        }

        customerName.text = eventRecord.customer ?? "-";
        statusValue.text = eventRecord.status
          ? this.$t(`BOOKINGS.${eventRecord.status}`)
          : "";
        statusValue.cls = eventRecord.cls;
        bookingDaysValue.text = eventRecord.bookingDays;
      }
    },
    beforeEventResizeFinalize(data) {
      console.log(data);
    },

    async viewBooking({ source }) {
      source.parent.parent?.close();

      let bookingId = this.selectedEventRecord.data.id;
      await this.$store.dispatch("bookings/get", bookingId);

      this.selectedOrderId = await this.$store.getters["bookings/booking"]
        .orderItem.salesOrder.id;

      this.$emit("onViewBooking", bookingId, this.selectedOrderId);

      this.selectedEventRecord = {};
    },

    async payBooking({ source }) {
      source.parent.parent?.close();

      let bookingId = this.selectedEventRecord.data.id;
      await this.$store.dispatch("bookings/get", bookingId);

      let booking = await this.$store.getters["bookings/booking"];
      let salesOrder = booking.orderItem.salesOrder;
      let customer = booking.customer;

      if (customer && !booking?.invoice_id) {
        this.$emit("onOpenConfirmBookingModal", salesOrder);
      }

      this.selectedEventRecord = {};
    },

    checkInBooking({ source }) {
      source.parent.parent?.close();

      this.$emit("onCheckInBooking", this.selectedEventRecord.data.id);

      this.selectedEventRecord = {};
    },

    checkOutBooking({ source }) {
      source.parent.parent?.close();

      this.$emit("onCheckOutBooking", this.selectedEventRecord.data.id);

      this.selectedEventRecord = {};
    },

    addBooking({ source }) {
      source.parent.parent?.close();

      this.$emit("onAddBooking", {
        spotId: this.selectedEventRecord.resourceData.id,
        startDate: DateHelper.format(
          this.selectedEventRecord._startDate,
          "YYYY-MM-DD"
        ),
        endDate: DateHelper.format(
          this.selectedEventRecord._endDate,
          "YYYY-MM-DD"
        ),
      });

      this.selectedEventRecord = {};
    },

    async editBooking({ source }) {
      source.parent.parent?.close();

      let bookingId = this.selectedEventRecord.data.id;
      await this.$store.dispatch("bookings/get", bookingId);

      this.selectedOrderId = await this.$store.getters["bookings/booking"]
        .orderItem.salesOrder.id;

      this.$emit("onEditBooking", bookingId, this.selectedOrderId);

      this.selectedEventRecord = {};
    },

    putOutOfService({ source }) {
      source.parent.parent?.close();

      const spotId = this.selectedEventRecord.resourceData.id;

      this.$emit("onOutOfService", {
        spotId,
        spot: this.getSpot(spotId),
        startDate: DateHelper.format(
          this.selectedEventRecord._startDate,
          "YYYY-MM-DD"
        ),
        endDate: DateHelper.format(
          this.selectedEventRecord._endDate,
          "YYYY-MM-DD"
        ),
      });

      this.selectedEventRecord = {};
    },

    putBackIntoService({ source }) {
      source.parent.parent?.close();

      const bookingId = this.selectedEventRecord.data.id;
      const spotId = this.selectedEventRecord.resourceData.id;

      const booking = this.getBooking(bookingId, this.getSpot(spotId));

      this.$emit("onBackIntoService", {
        bookingId,
        booking,
        startDate: this.selectedEventRecord._startDate,
        endDate: this.selectedEventRecord._endDate,
      });

      this.selectedEventRecord = {};
    },

    goTo(number) {
      this.isToday = false;
      this.startDate = DateHelper.add(this.startDate, number, "days");
      this.endDate = DateHelper.add(this.endDate, number, "days");
    },

    today() {
      const startDate = DateHelper.add(new Date(this.currentDate), -5, "days");
      const endDate = DateHelper.add(new Date(this.currentDate), 30, "days");

      if (this.startDate > startDate) {
        this.startDate = startDate;
        this.$nextTick(function () {
          this.endDate = endDate;
        });
      } else {
        this.endDate = endDate;
        this.$nextTick(function () {
          this.startDate = startDate;
        });
      }

      this.isToday = true;
    },

    cleanSpot({ record }) {
      this.$emit("onCleanSpot", record.data.id, record.data.clean_condition);
    },

    getSpot(spotId) {
      return this.spots.find((spot) => spot.id === spotId);
    },

    getResource(id) {
      return this.resources.find((rs) => rs.id === id);
    },

    getBooking(bookingId, spot) {
      return spot.bookings.find((booking) => booking.id === bookingId);
    },

    /**
     *
     * @param {moment()} newStartDate
     * @param {moment()} newEndDate
     * @param {start_at: moment(), end_at: moment()} bookingDates
     *
     * @return boolean
     */
    isOverlapping(newStartDate, newEndDate, bookingDates) {
      // nos nouvelles dates sont dans l'intervalle de dates du booking
      let overlapping0 =
        newStartDate.isBetween(bookingDates.start_at, bookingDates.end_at) ||
        newEndDate.isBetween(bookingDates.start_at, bookingDates.end_at);

      // c'est plutot les dates de la reservation qui sont toutes les 2 dans l'intervalle des nouvelles dates
      let overlapping1 =
        bookingDates.start_at.isBetween(newStartDate, newEndDate) &&
        bookingDates.end_at.isBetween(newStartDate, newEndDate);

      // nous nouvelles dates sont a une des bornes des dates de la reservations
      let overlapping3 =
        newStartDate.isSame(bookingDates.start_at) ||
        newEndDate.isSame(bookingDates.end_at);

      return overlapping0 || overlapping1 || overlapping3;
    },

    /**
     *
     * @param {moment()} newStartDate
     * @param {moment()} newEndDate
     * @param {[{start_at: moment(), end_at: moment()}]} bookingsDates
     *
     * @return boolean
     */
    isOverlappingOnBookings(newStartDate, newEndDate, bookingsDates) {
      let i = 0;
      let len = bookingsDates?.length;
      if (len) {
        for (; i < len; i++) {
          if (this.isOverlapping(newStartDate, newEndDate, bookingsDates[i])) {
            return true;
          }
        }
      }

      return false;
    },
  },

  watch: {
    startDate() {
      this.renderKey++;
    },
    endDate() {
      this.renderKey++;
      this.$emit("onDateChanged", [this.startDate, this.endDate], this.isToday);
    },
  },
};
</script>

<style lang="scss">
@import "~@bryntum/scheduler/scheduler.stockholm.css";

.clean-round,
.dirty-round,
.circle {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 9999px;
}

.dirty-round {
  background-color: #e81212;
}

.clean-round {
  background-color: #72e812;
}
.circle.not-guaranteed {
  background-color: var(--primary);
}

.long-text {
  width: auto;
}

.b-timeline-subgrid .shaded {
  border-inline-end: 2px solid red;
  background-color: #fafafa8c;
}
.b-grid-header .b-sch-timerange {
  background-color: #fafafa8c;
}
.b-sch-range.shaded {
  background-color: transparent;
}
</style>
