<template>
  <div>
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>

    <!-- <h3>{{ title }}</h3> -->

    <div class="description">
      <ul class="space-y-4">
        <li v-for="(policy, i) in policies" :key="policy.id">
          <policy-content :policy="policy" :rank="i + 1" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PolicyContent from "./PolicyContent.vue";
import { mapGetters } from "vuex";

export default {
  name: "policies-body-list",

  components: {
    PolicyContent,
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      policies: [],
    };
  },

  async mounted() {
    await this.fetchPolicies(this.type);
  },
  async updated() {},

  watch: {
    type: {
      handler: async function (val) {
        await this.fetchPolicies(val);
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
  },

  methods: {
    async fetchPolicies(type) {
      try {
        this.loading = true;

        const query = {
          filter: {
            organization_id: this.globalOrganization.id,
            in_types: type,
          },
        };

        this.policies = await this.$store.dispatch(
          "policies/getPublicPolicies",
          query
        );

        console.log("this.policies => ", this.policies);
      } catch (error) {
        console.error("fetchPolicies => ", error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
