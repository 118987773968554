<template>
  <div class="item-inner">
    <div class="item-inner-left">
      <el-carousel :autoplay="false" arrow="never" indicator-position="inside">
        <el-carousel-item
          v-for="img in spot.gallery.filter((item) => !!item)"
          :key="img"
        >
          <img :src="img ? $pictureResize(img, 300) : null" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="item-inner-center">
      <div class="title">
        <h2>{{ spot.spotType.name }}</h2>
        <span>{{ spot.name }} {{ spot.code }}</span>
      </div>
      <div class="roulotte">
        <ul>
          <li>
            <img
              :src="
                spot.category.icon
                  ? spot.category.icon
                  : '/img/default-category-icon.svg'
              "
              style="max-height: 35px; display: inline"
              alt="icon"
            />
            <span>{{ spot.category.name }}</span>
          </li>
          <li>
            <img src="/img/kw-persons-icon.svg" alt="persons" />
            <span> {{ spot.capacity }} pers</span>
          </li>
          <li>
            <img src="/img/kw-pets.svg" alt="pets" />
            <span>{{ spot.pets_capacity }}</span>
          </li>
        </ul>
      </div>
      <div class="equipment">
        <h4>{{ $t("SPOTS.SPOT_EQUIPMENTS") }}</h4>
        <p>{{ spot.other_equipments.map((item) => item.name).join(", ") }}</p>
      </div>
      <div class="description">
        <h4>{{ $t("COMMON.EXCERPT") }}</h4>
        <p v-html="spot.excerpt"></p>
      </div>
    </div>
    <div class="item-inner-right">
      <div class="pricing">
        <p>
          <span> {{ spot.pricing.avg }} $</span>
          / {{ $t("BOOKINGS.PRICE_PER_NIGHT") }}
        </p>
      </div>
      <div class="total">
        <p>
          <span>{{ spot.pricing.total_nights}} CAD</span>
          {{ $t("COMMON.TOTAL") }}
        </p>
      </div>
      <div class="status">
        <el-tag v-if="spot.availability === 'AVAILABLE'" type="success">
          {{ $t("BOOKINGS.AVAILABLE") }}
        </el-tag>
        <el-tag v-if="spot.availability === 'PARTIALY_AVAILABLE'" type="warning">
          {{ $t("BOOKINGS.PARTIALLY_AVAILABLE") }}
        </el-tag>
        <el-tag v-if="spot.availability === 'NOT_AVAILABLE'" type="danger">
          {{ $t("BOOKINGS.NOT_AVAILABLE") }}
        </el-tag>
        <el-tag class="draft" v-if="spot.locked"
          ><img src="/img/kw-loading.svg" alt="icon"
        /></el-tag>
      </div>
      <!--<div class="reservation">
        <button class="info">
          {{ $t("COMMON.MORE_INFOS") }}
        </button>
        <button class="reserve">
          {{ $t("COMMON.RESERVATION") }}
        </button>
      </div>-->
    </div>
  </div>
</template>
<script>
import { Carousel, CarouselItem, Tag } from "element-ui";
import { SPOT_STATUS_ACTIVE } from "@/constants/spots";

export default {
  name: "booking-spot-list-item",
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Tag.name]: Tag,
  },

  props: {
    spot: {
      type: Object,
      default: null,
      description: "Spot object",
    },
    size: {
      type: String,
      default: "md",
      required : false,
      description: "Spot Card Size",
    },
  },

  data() {
    return {
      SPOT_STATUS_ACTIVE,
    };
  },
};
</script>
