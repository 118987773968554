<template>
  <div class="spot-selector">
    <div class="spot-selector-header">
      <div class="spot-selector-header-left">
        <base-input
          v-model="searchQuery"
          class="search"
          max-width="220"
          prepend-icon="far fa-search"
          :placeholder="'Rechercher par nom ou code'"
          clearable
        />
      </div>
      <div class="spot-selector-header-right">
        <el-button class="cancel" type="default" @click="handleClosed">
          {{ $t("COMMON.CANCEL") }}
        </el-button>
      </div>
    </div>
    <div class="spot-selector-count">
      <p>
        {{ filteredSpots.length }} / {{ spots.length }}
        {{ $t("SPOTS.FIND_SPOTS") }}
      </p>
    </div>
    <!-- Liste des spots -->
    <div class="spot-selector-list">
      <div
        class="spot-selector-list-item"
        v-for="spot in filteredSpots"
        :key="spot.id"
      >
        <div @click="handleSpotSelected(spot)">
          <div
            class="spot-selector-list-item-inner"
            :class="{ selected: checkIfSelected(spot) }"
          >
            <el-carousel
              :autoplay="false"
              arrow="never"
              class="mb-1"
              indicator-position="inside"
            >
              <el-carousel-item
                v-for="img in spot.gallery.filter((item) => !!item)"
                :key="img"
              >
                <img :src="img ? $pictureResize(img, 300) : null" />
              </el-carousel-item>
            </el-carousel>

            <div class="spot-info">
              <div class="spot-info-header">
                <div class="spot-info-header-left">
                  <h3>{{ spot.spotType.name }}</h3>
                  <p>{{ spot.name }} {{ spot.code }}</p>
                </div>
                <div class="spot-info-header-right">
                  <div class="price">
                    <p>
                      {{ spot.pricing.avg }} $
                      <span>/ {{ $t("BOOKINGS.PRICE_PER_NIGHT") }}</span>
                    </p>
                  </div>
                  <div class="total">
                    <p>
                      {{ spot.pricing.total_nights }} CAD
                      <span>{{ $t("COMMON.TOTAL") }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="spot-info-services">
                <ul>
                  <li>
                    <img
                      :src="
                        spot.category.icon
                          ? spot.category.icon
                          : '/img/default-category-icon.svg'
                      "
                      style="max-height: 35px; display: inline"
                      alt="icon"
                    />
                    <span>{{ spot.category.name }}</span>
                  </li>
                  <li>
                    <img src="/img/kw-persons-icon.svg" alt="persons" />
                    <span> {{ spot.capacity }} pers</span>
                  </li>
                  <li>
                    <img src="/img/kw-pets.svg" alt="pets" />
                    <span>{{ spot.pets_capacity }}</span>
                  </li>
                </ul>
              </div>

              <!-- <el-tag type="info">{{ spot.spotType.name }}</el-tag> -->

              <div class="spot-info-status">
                <el-tag v-if="spot.availability === 'AVAILABLE'" type="success">
                  {{ $t("BOOKINGS.AVAILABLE") }}
                </el-tag>
                <el-tag
                  v-if="spot.availability === 'PARTIALY_AVAILABLE'"
                  type="warning"
                >
                  {{ $t("BOOKINGS.PARTIALLY_AVAILABLE") }}
                </el-tag>
                <el-tag
                  v-if="spot.availability === 'NOT_AVAILABLE'"
                  type="danger"
                >
                  {{ $t("BOOKINGS.NOT_AVAILABLE") }}
                </el-tag>
                <el-tag class="draft" v-if="spot.locked">
                  <img src="/img/kw-loading.svg" alt="icon" />
                </el-tag>
              </div>
              <div class="spot-info-description">
                <h4>Description</h4>
                <p v-html="spot.excerpt"></p>
              </div>
            </div>
          </div>

          <!-- <booking-spot-list-item 
          :spot="spot" 
          :class="{ selected: checkIfSelected(spot) }"
          :size="'sm'" /> -->
        </div>
      </div>
    </div>

    <!-- Loader -->
    <span class="resize-loading" v-if="isLoadingList">
      <span class="loader"></span>
    </span>
  </div>
</template>

<script>
import {
  Tag,
  Row,
  Col,
  Card,
  Carousel,
  CarouselItem,
  Button,
} from "element-ui";
import { mapGetters } from "vuex";
import BookingSpotListItem from "@/views/Pages/BookingModule/BookingManagement/partials/SpotListItem.vue";

export default {
  layout: "SpotsSelector",

  components: {
    // BookingSpotListItem,
    [Tag.name]: Tag,
    [Card.name]: Card,
    [Button.name]: Button,

    [Row.name]: Row,
    [Col.name]: Col,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },

  mixins: [],

  props: {
    value: {
      // 🔹 Ajout de `value` pour gérer v-model
      type: [String, Number],
      required: true,
    },

    filterData: {
      type: [Object],
      required: false,
    },
  },

  data() {
    return {
      isLoadingList: false,
      spots: [],
      searchQuery: "", // chqn;ps de recherche
    };
  },

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),

    filteredSpots() {
      if (!this.searchQuery) {
        return this.spots;
      }
      const query = this.searchQuery.toLowerCase();
      return this.spots.filter(
        (spot) =>
          spot.name.toLowerCase().includes(query) ||
          spot.code.toLowerCase().includes(query)
      );
    },
  },

  watch: {
    isLoadingList: {
      handler: async function (newVal) {
        this.$emit("onSpotsListLoading", {
          loading: newVal,
          // spot : this.spot,
        });
      },
      immediate: true,
    },
    searchQuery: {
      handler: function (newVal) {
        this.$emit("OnSearchingSpots", newVal);
      },
      deep: true,
    },

    filterData: {
      handler: async function (newVal) {
        await this.getSpotsList();
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    // this.getSpotsList();
  },

  methods: {
    async getSpotsList() {
      try {
        this.isLoadingList = true;

        const filter = this.filterData;
        filter.organization = this.globalOrganization?.id;

        const params = {
          sort: "code",
          include: `organization,category,spotType,other_equipments`,
          filter,
        };

        // Recuperation des spots
        await this.$store.dispatch("spots/list", params);

        this.spots = this.$store.getters["spots/list"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        console.error("getSpotsList[errot] => ", error);
      } finally {
        this.isLoadingList = false;
      }
    },

    handleSpotSelected(selectedSpot) {
      this.$emit("input", selectedSpot.id); // 🔹 Mise à jour de `v-model`
      this.$emit("onSpotSelected", selectedSpot);

      console.log("Card-handleSpotSelectedId => ", selectedSpot.id);
    },

    handleClosed() {
      this.$emit("onSelectorClosed");
    },

    checkIfSelected(spot) {
      return spot.id === this.value; // 🔹 Comparer avec `value`
    },
  },
};
</script>
