<template>
  <div class="step-body step-1">
    <!-- start_at, end_at -->
    <div class="form-wrapper width_1">
      <base-input class="form-wrapper full">
        <el-date-picker
          v-model="selectedDateRange"
          type="daterange"
          start-placeholder="Date de début"
          end-placeholder="Date de fin"
          format="dd MMM yyyy"
          value-format="yyyy-MM-dd"
          :min="new Date()"
          :max="$moment().add(maxMonthsCalendar, 'months').toDate()"
          :clearable="false"
          :picker-options="{
            disabledDate: disabledDates,
            cellClassName: dateCellClassName,
          }"
          @change="onDateSelected"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.start_at" />
      <validation-error
        v-if="!isDateRangeValid && !loadingCalendar"
        :errors="[$t('BOOKINGS.INVALID_DATE_RANGE')]"
      />
      <validation-error
        v-if="!isPricingValid && !loadingPricing"
        :errors="[$t('BOOKINGS.INVALID_PRICING_FOR_DATE_RANGE')]"
      />
    </div>

    <!-- adult capacity -->
    <div class="form-wrapper width_1-3">
      <base-input :label="`${$t('SPOTS.SPOT_ADULT_CAPACITY')}`">
        <el-select
          :label="$t('SPOTS.SPOT_ADULT_CAPACITY')"
          :placeholder="$t('SPOTS.SPOT_ADULT_CAPACITY')"
          v-model="booking.adults_count"
          @change="onAdultCapacityChanged"
        >
          <el-option
            v-for="n in Array.from({ length: maxAdults }, (_, i) => i + 1)"
            :key="n"
            :value="n"
            :label="n"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.adults_count" />
    </div>

    <!-- children capacity -->
    <div class="form-wrapper width_1-3">
      <base-input :label="`${$t('SPOTS.SPOT_CHILDREN_CAPACITY')}`">
        <el-select
          :label="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
          :placeholder="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
          v-model="booking.children_count"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in maxChildren + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.children_count" />
    </div>

    <!-- pets capacity -->
    <div class="form-wrapper width_1-3">
      <base-input :label="`${$t('SPOTS.SPOT_PETS_CAPACITY')}`">
        <el-select
          :label="$t('SPOTS.SPOT_PETS_CAPACITY')"
          :placeholder="$t('SPOTS.SPOT_PETS_CAPACITY')"
          v-model="booking.pets_count"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in maxPets + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.pets_count" />
    </div>

    <div class="form-wrapper width_1">
      <label class="pers-count">
        {{ $t("BOOKINGS.CAPACITY") }} :
        {{ booking.adults_count + booking.children_count }} pers
      </label>
    </div>

    <!-- beds qty -->
    <div class="form-wrapper width_1" v-if="!displayBedRoomQuantityField">
      <base-input :label="`${$t('SPOTS.BEDS_TOTAL_QTY')}`">
        <el-select
          :label="$t('SPOTS.BEDS_TOTAL_QTY')"
          :placeholder="$t('SPOTS.BEDS_TOTAL_QTY')"
          v-model="booking.beds_qty"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in spot.beds_total_qty + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.beds_qty" />
    </div>

    <!-- rooms qty -->
    <div class="form-wrapper width_1" v-if="!displayBedRoomQuantityField">
      <base-input
        :label="`${$t('SPOTS.ROOMS_QTY')}`"
        :placeholder="$t('SPOTS.ROOMS_QTY')"
        type="number"
        :max="10"
        :min="0"
        v-model="booking.rooms_qty"
        @change="onFormChanged"
      >
      </base-input>

      <validation-error :errors="apiValidationErrors.rooms_qty" />
    </div>

    <!-- TODO Arrèter de retirer ce champs il est important -->
    <div class="form-wrapper width_1">
      <base-input
        :label="`${$t('SPOTS.CHANGE_RATES')}`"
        :placeholder="$t('SPOTS.CHANGE_RATES')"
        v-model="booking.pricing_custom"
        @change="onPricingCustomChanged"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.pricing_custom" />
    </div>

    <div
      class="form-wrapper width_1"
      v-if="isLandOrSeasonalFormType && displayAllowedEquipmetField"
    >
      <base-input :label="`${$t('SPOTS.EQUIPMENT_SELECTION')}`">
        <allowed-equipment-selector
          :value="booking.equipment"
          :options-value="spot.allowed_equipment"
          @valueChanged="(value) => (booking.equipment = value)"
          allowNone
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.equipment" />
    </div>

    <!-- equipment length -->
    <div
      class="form-wrapper width_1"
      v-if="
        isLandOrSeasonalFormType &&
        booking.equipment &&
        booking.equipment !== TENT &&
        displayEquipmentLengthField
      "
    >
      <base-input
        type="text"
        :min="0"
        :max="maxEquipmentLength"
        :label="$t('SPOTS.EQUIPMENT_LENGTH')"
        :placeholder="$t('SPOTS.EQUIPMENT_LENGTH')"
        :name="`'${$t('SPOTS.EQUIPMENT_LENGTH')}'`"
        :rules="equipmentLengthValidationRules"
        v-model="booking.equipment_length"
        @change="onFormChanged"
      >
        <template #label>
          <label class="form-control-label" for="">
            {{ $t("SPOTS.EQUIPMENT_LENGTH") }}
            <span v-if="maxEquipmentLength" class="h5 text-muted font-italic">
              ({{
                $t("SPOTS.MAX_LENGTH", { length: maxEquipmentLength ?? 0 })
              }})
            </span>
          </label>
        </template>
      </base-input>
      <validation-error :errors="apiValidationErrors.equipment_length" />
    </div>

    <div
      class="form-wrapper width_1-2"
      v-if="!isLandFormType && displayDriverExtensionsField"
    >
      <base-input :label="`${$t('SPOTS.DRIVER_EXTENSION')}`">
        <el-select
          :label="$t('SPOTS.DRIVER_EXTENSION')"
          :placeholder="$t('SPOTS.DRIVER_EXTENSION')"
          v-model="booking.driver_extensions"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in spot.driver_extensions + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.rate" />
    </div>

    <div
      class="form-wrapper width_1-2"
      v-if="!isLandFormType && displayPassengerExtensionsField"
    >
      <base-input :label="`${$t('SPOTS.PASSENGER_EXTENSION')}`">
        <el-select
          :label="$t('SPOTS.PASSENGER_EXTENSION')"
          :placeholder="$t('SPOTS.PASSENGER_EXTENSION')"
          v-model="booking.passenger_extensions"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in spot.passenger_extensions + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.passenger_extensions" />
    </div>

    <div
      class="form-wrapper width_1"
      v-if="isLandOrSeasonalFormType && displayedElectricityField"
    >
      <base-input :label="`${$t('SPOTS.ELECTRICITY')}`">
        <electricity-selector
          :options-value="spot.electricity"
          :value="booking.electricity"
          @valueChanged="(value) => (booking.electricity = value)"
          allow-none
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.electricity" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('BOOKINGS.SPECIAL_REQUEST')"
        :placeholder="$t('BOOKINGS.SPECIAL_REQUEST')"
      >
        <el-input type="textarea" v-model="booking.special_request"></el-input>
      </base-input>
      <validation-error :errors="apiValidationErrors.special_request" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('BOOKINGS.INTERNAL_NOTE')"
        :placeholder="$t('BOOKINGS.INTERNAL_NOTE')"
      >
        <el-input type="textarea" v-model="booking.comment"></el-input>
      </base-input>
      <validation-error :errors="apiValidationErrors.comment" />
    </div>

    <div class="form-wrapper width_1 summary">
      <label class="pers-count"> {{ $t("SPOTS.OPTIONS") }} </label>
      <div class="mt-2">
        <base-checkbox v-model="booking.is_spot_blocked">
          {{ $t("BOOKINGS.BLOCK_THIS_SPOT") }}
        </base-checkbox>
      </div>
    </div>
    
   <ButtonViewPolicies />

    <div class="form-wrapper width_1 buttons-group">
      <el-button
        :disabled="
          !isDateRangeValid ||
          !isPricingValid ||
          loadingPricing ||
          loadingCalendar
        "
        class="continue"
        type="primary"
        @click="handleSubmit"
      >
        <span v-if="loadingPricing || loadingCalendar" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.CONTINUE") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Header,
  Image,
  Row,
  Step,
  Steps,
  Select,
  Option,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import "flatpickr/dist/flatpickr.css";
import ValidationError from "@/components/ValidationError";
import defaultBooking from "../../defaultBooking";
import defaultSpot from "../../../../SpotModule/SpotManagement/defaultSpot";
import AllowedEquipmentSelector from "@/components/AllowedEquipmentSelector.vue";
import ElectricitySelector from "@/components/ElectricitySelector.vue";
import {
  BOOKING_FORM_TYPE_LAND,
  BOOKING_FORM_TYPE_PROPERTY,
  BOOKING_FORM_TYPE_READY_TO_CAMP,
  BOOKING_FORM_TYPE_SEASONAL,
} from "@/constants/spotCategories";
import { TENT } from "@/constants/allowedEquipments";
import swal from "sweetalert2";
import moment from "moment/moment";
import { mapGetters } from "vuex";

import ButtonViewPolicies from "@/views/Pages/PolicyModule/PolicyManagement/components/ButtonViewPolicies.vue";

export default {
  name: "booking-spot-selection",

  components: {
    ValidationError,
    ElectricitySelector,
    AllowedEquipmentSelector,
    [Header.name]: Header,
    [Divider.name]: Divider,
    [Row.name]: Row,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    ButtonViewPolicies,
  },

  mixins: [formMixin],

  props: {
    bookingData: {
      type: Object,
      default: defaultBooking,
      description: "Booking object",
    },
    spot: {
      type: Object,
      default: defaultSpot,
      description: "Spot object",
    },
  },

  data() {
    let selectedDateRange = [];

    if (this.bookingData.start_at) {
      selectedDateRange = [this.bookingData.start_at, this.bookingData.end_at];
    }

    this.bookingData.adults_count =
      this.bookingData.adults_count <= 0 ? 1 : this.bookingData.adults_count;

    const maxEquipmentLength = this.spot.equipment_length ?? 0;
    let equipmentLengthValidationRules = `numeric|min_value:1|max_value:${maxEquipmentLength}`;
    if (maxEquipmentLength === 0) {
      equipmentLengthValidationRules = "numeric|min_value:1";
    }

    return {
      booking: this.bookingData,
      selectedDateRange: selectedDateRange,
      maxAdults: this.spot.adults_capacity ?? 0,
      maxChildren: this.spot.children_capacity ?? 0,
      maxPets: this.spot.pets_capacity ?? 0,
      maxEquipmentLength: maxEquipmentLength,
      showBookingCheckModal: false,
      loading: false,
      loadingPricing: true,
      loadingCalendar: true,
      formErrors: null,
      BOOKING_FORM_TYPE_LAND,
      BOOKING_FORM_TYPE_PROPERTY,
      TENT,
      equipmentLengthValidationRules: equipmentLengthValidationRules,
      bookingPricingChanged: false,
      datesRangeErrors: null,
    };
  },

  async created() {},

  mounted() {
    this.getCalendar();
    this.getPricingDebounced();
  },

  computed: {
    ...mapGetters("spots", { calendar: "calendar" }),
    ...mapGetters("bookings", { pricing: "pricing" }),
    shouldIDisplayBedsQuantityField() {
      return (
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_LAND &&
        this.spot.category.booking_form_type !==
          BOOKING_FORM_TYPE_READY_TO_CAMP &&
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_SEASONAL
      );
    },

    displayAllowedEquipmetField() {
      return (
        this.spot.allowed_equipment && this.spot.allowed_equipment.length > 0
      );
    },

    displayDriverExtensionsField() {
      return this.spot.driver_extensions > 0;
    },

    displayPassengerExtensionsField() {
      return this.spot.passenger_extensions > 0;
    },
    displayBedRoomQuantityField() {
      return (
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_LAND ||
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_READY_TO_CAMP
      );
    },
    displayedElectricityField() {
      return (
        Array.isArray(this.spot.electricity) && this.spot.electricity.length > 0
      );
    },
    displayEquipmentLengthField() {
      return this.spot.equipment_length > 0;
    },
    isReadyToCampOrChaletOrProperty() {
      return (
        this.spot.category.booking_form_type !==
          BOOKING_FORM_TYPE_READY_TO_CAMP ||
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_PROPERTY
      );
    },
    isSeasonalFormType() {
      return (
        this.spot.category.booking_form_type === BOOKING_FORM_TYPE_SEASONAL
      );
    },
    isLandFormType() {
      return this.spot.category.booking_form_type === BOOKING_FORM_TYPE_LAND;
    },
    isLandOrSeasonalFormType() {
      return this.isLandFormType || this.isSeasonalFormType;
    },
    isDateRangeValid() {
      const start = moment(this.booking.start_at);
      const end = moment(this.booking.end_at);

      let isAvailable = false;
      if (this.calendar?.booked_nights) {
        let current = start.clone();
        while (current.isBefore(end)) {
          const formatedDate = current.format("YYYY-MM-DD");
          if (this.calendar.booked_nights.includes(formatedDate)) {
            if (
              !this.calendar.booking_start_dates.includes(formatedDate) ||
              this.calendar.booking_end_dates.includes(formatedDate)
            ) {
              return false;
            }
          }
          current.add(1, "days");
        }
        isAvailable = true;
      }
      return (
        this.booking.start_at &&
        this.booking.end_at &&
        this.booking.start_at < this.booking.end_at &&
        isAvailable
      );
    },
    isPricingValid() {
      if (!this.booking.start_at || !this.booking.end_at) return false;
      const nights = moment(this.booking.end_at).diff(
        moment(this.booking.start_at),
        "days"
      );
      return this.pricing?.total > 0 && nights > 0;
    },

    maxMonthsCalendar() {
      return process.env.VUE_APP_SPOT_CALENDAR_MONTHS_LIMIT ?? 30;
    },
  },

  methods: {
    async handleSubmit() {
      try {
        swal.showLoading();
        this.loading = true;

        if (this.pricing.total <= 0) {
          this.$notify({
            type: "danger",
            message: this.$t("SPOTS.NO_PRICING_FOUND_FOR_SPOT"),
          });

          swal.close();
          return;
        }
        await this.sendInformation();
      } catch (error) {
        console.error(error);
        this.formErrors = error?.response?.data?.errors;
      } finally {
        swal.close();
        this.loading = false;
      }
    },

    async sendInformation() {
      const bookingData = cloneDeep(this.booking);

      bookingData.start_at = this.$convertDateToUtcDayString(
        bookingData.start_at
      );
      bookingData.end_at = this.$convertDateToUtcDayString(bookingData.end_at);

      if (!bookingData.customer?.id) {
        delete bookingData.customer;
      }

      if (!bookingData.spot?.id) {
        bookingData.spot.id = this.spot.id;
      }

      delete bookingData.order;

      if (bookingData.id) {
        delete bookingData.createdBy;
        delete bookingData.orderItem;

        await this.$store.dispatch("bookings/update", bookingData);
      } else {
        await this.$store.dispatch("bookings/add", bookingData);
      }

      const createdBooking = await this.$store.getters["bookings/booking"];
      this.booking.id = createdBooking.id;

      this.$emit("draftBookingCreated", createdBooking);
    },
    onPricingCustomChanged(price) {
      if(this.booking.pricing_custom) {
        this.booking.pricing_custom = parseFloat(this.booking.pricing_custom.replace(',', '.'));
      }
      this.onFormChanged();
    },
    onFormChanged() {
      this.$emit("onFormChanged", this.booking);
    },

    onDateSelected(range) {
      this.booking.start_at = range[0];
      this.booking.end_at = range[1];
      this.bookingPricingChanged = true;
      this.onFormChanged();
    },

    onAdultCapacityChanged(capacity) {
      this.booking.adults_count = capacity;
      this.bookingPricingChanged = true;
      this.onFormChanged();
    },

    /**
     * Mettre a 0 l'heure d'une date avec heure.
     *
     * @param {Date} date
     * @returns {Date}
     */
    setDateTimeComponentToZero(date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0, 0);
      return date;
    },

    dateCellClassName(date) {
      if (!this.calendar) {
        return "disabled";
      }
      const formatedDate = moment(date).format("YYYY-MM-DD");
      if (this.calendar.booked_nights.includes(formatedDate)) {
        return "booked";
      }
      if (this.calendar.booking_end_dates.includes(formatedDate)) {
        return "end";
      }
      if (this.calendar.booking_start_dates.includes(formatedDate)) {
        return "start";
      }
      return "avaibale";
    },

    disabledDates(date) {
      const momentDate = moment(date);
      const formatedDate = momentDate.format("YYYY-MM-DD");
      if (this.calendar.booked_nights.includes(formatedDate)) {
        if (
          !this.calendar.booking_start_dates.includes(formatedDate) ||
          this.calendar.booking_end_dates.includes(formatedDate)
        ) {
          return true;
        }
      }
      return (
        momentDate.isBefore(moment().startOf("day")) ||
        momentDate.isAfter(
          moment().add(this.maxMonthsCalendar, "months").startOf("day")
        )
      );
    },

    setModal(config) {
      config = {
        ...{
          title: null,
          html: null,
          type: "warning",
          customClass: {
            popup: "delete-popup",
          },
          buttonsStyling: false,
          showCancelButton: false,
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonText: this.$t("COMMON.CLOSE"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        },
        ...config,
      };
      return swal.fire(config);
    },

    async getCalendar() {
      if (this.spot.id) {
        this.loadingCalendar = true;
        await this.$store.dispatch("spots/getCalendar", {
          id: this.spot.id,
        });
        this.loadingCalendar = false;
      }
    },

    getPricingDebounced: _.debounce(function () {
      this.getPricing();
    }, 300),

    async getPricing() {
      if (this.spot.id) {
        this.loadingPricing = true;
        await this.$store.dispatch("bookings/getPricing", {
          start_at: this.booking.start_at,
          end_at: this.booking.end_at,
          spot_id: this.spot.id,
          adults_count: this.booking.adults_count,
          pricing_custom: this.booking.pricing_custom,
          organization_id: this.booking.organization.id,
        });
        this.loadingPricing = false;
      }
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    bookingData: {
      handler: function (bookingData) {
        if (bookingData) {
          this.booking = {
            ...this.booking,
            ...cloneDeep(bookingData),
          };
          this.booking.adults_count =
            bookingData.adults_count < 2 ? 2 : bookingData.adults_count;
          this.booking.children_count = bookingData.children_count;
          this.booking.pets_count = bookingData.pets_count;
          this.getPricingDebounced();
        }
      },
      deep: true,
    },
    booking: {
      handler: function () {
        this.getPricingDebounced();
      },
      deep: true,
    },
    spot: {
      handler: function () {
        this.getCalendar();
      },
      deep: true,
    },
  },
};
</script>
