<template>
  <div class="shopping-cart">
    <router-link
      :to="{ name: 'Pending Bookings' }"
      v-slot="{ href, navigate }"
      custom
    >
      <a :href="href" @click="navigate">
        <span class="icon">
          <span class="count">{{ cart.length }}</span>
          <img src="/img/shopping-cart.svg" alt="shopping-cart" />
        </span>
        <span class="text">
          {{ $t("BOOKINGS.PENDING_BOOKING_LIST") }}
        </span>
      </a>
    </router-link>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { mapGetters } from "vuex";

export default {
  name: "shopping-cart",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {},

  data() {
    return {
      count: 0,
    };
  },

  setup() {},

  created() {},

  computed: {
    ...mapGetters("profile", { me: "me" }),
    ...mapGetters("salesOrders", { cart: "cart" }),
  },

  methods: {},

  watch: {},
};
</script>
