<template>
  <modal :show="showModal" modal-classes="kw-modal add-file" size="lg">
    <div slot="header">
      <h2>{{ $t("COMMON.ADD_PAYMENT") }}</h2>
      <button @click="closeModal">
        <i class="fal fa-times"></i>
      </button>
    </div>
    <div>
      <form
        class="add-form"
        ref="profile_form"
        @submit.prevent="handleSubmit"
        @keydown.enter.prevent="() => {}"
      >
        <div class="add-form-inner">
          <div class="form-wrapper full">
            <base-input :label="`${$t('INVOICES.PAYMENT_TYPE')} (*)`">
              <div class="radio-group-wrapper">
                <el-radio-group v-model="paymentModel.payment_type">
                  <el-radio :label="PAYMENT_TYPE_REFUND">
                    {{ $t("INVOICES.PAYMENT_TYPE_REFUND") }}
                  </el-radio>
                  <el-radio :label="PAYMENT_TYPE_PAYMENT">
                    {{ $t("INVOICES.PAYMENT_TYPE_PAYMENT") }}
                  </el-radio>
                </el-radio-group>
              </div>
            </base-input>
          </div>
          <div class="form-wrapper full" v-if="!!paymentModel.payment_type">
            <base-input :label="`${$t('COMMON.PAYMENTS_METHODS')}  (*)`">
              <el-select
                v-model="paymentModel.source"
                :placeholder="$t('COMMON.SELECT_PAYMENT_METHODS')"
              >
                <el-option
                  v-for="(method, index) in salesInvoice.organization
                    .payment_methods"
                  :key="index"
                  :label="$t(`COMMON.PAYMENT_METHOD_${method}`)"
                  :value="method"
                />
              </el-select>
            </base-input>
            <validation-error :errors="apiValidationErrors.payment_methods" />
          </div>
          <div
            class="form-wrapper full"
            v-if="
              !!paymentModel.payment_type &&
              paymentModel.source == PAYMENT_METHOD_CHECK
            "
          >
            <base-input
              :label="`${$t('INVOICES.CHECK_NUMBER')} (*)`"
              :placeholder="$t('INVOICES.CHECK_NUMBER')"
              v-model="paymentModel.transactionData.check_number"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.excerpt" />
          </div>

          <div
            class="form-wrapper full"
            v-if="
              !!paymentModel.payment_type &&
              paymentModel.source == PAYMENT_METHOD_CLOVER
            "
          >
            <credit-card-selector
              :customer-id="salesInvoice.recipient.id"
              v-model="paymentModel.creditCard.id"
              @creditCardChanged="
                (creditCard) => {
                  paymentModel.creditCard = {
                    type: 'credit-cards',
                    id: creditCard,
                  };
                }
              "
            />
            <validation-error :errors="apiValidationErrors.excerpt" />
          </div>

          <div class="form-wrapper full" v-if="!!paymentModel.payment_type">
            <base-input
              v-model="paymentModel.amount"
              :label="`${$t('INVOICES.AMOUNT')} (*)`"
              :placeholder="$t('INVOICES.AMOUNT')"
              type="number"
              :min="minAmount"
              :max="maxAmount"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.amount" />
          </div>

          <div class="form-wrapper full" v-if="!!paymentModel.payment_type">
            <base-input
              :label="`${$t('INVOICES.EXCERPT')} ${
                paymentModel.source == PAYMENT_METHOD_OTHER ? '(*)' : ''
              }`"
              :placeholder="$t('INVOICES.EXCERPT')"
            >
              <html-editor v-model="paymentModel.excerpt"> </html-editor>
            </base-input>
            <validation-error :errors="apiValidationErrors.excerpt" />
          </div>
        </div>
      </form>
    </div>
    <template slot="footer">
      <button
        type="button"
        class="btn close"
        @click="closeModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CANCEL") }}
      </button>

      <button
        type="submit"
        class="btn add"
        @click="handleAddPayment"
        :disabled="loading || !canAddPayment"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.CONTINUE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import { Button, Select, Option, Radio, RadioGroup } from "element-ui";
import { paymentMethodsOptions } from "@/constants/common";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { cloneDeep } from "lodash";
import {
  PAYMENT_STATUS_COMPLETED,
  PAYMENT_TYPE_PAYMENT,
  PAYMENT_TYPE_REFUND,
} from "@/constants/payments";
import {
  PAYMENT_METHOD_OTHER,
  PAYMENT_METHOD_CHECK,
  PAYMENT_METHOD_CLOVER,
} from "@/constants/common";
import CreditCardSelector from "@/components/CreditCardSelector.vue";

export default {
  name: "sales-invoice-view-global",

  components: {
    BaseInput,
    ValidationError,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    HtmlEditor,
    CreditCardSelector,
  },

  props: ["salesInvoice", "showModal", "amountToPay"],

  mixins: [formMixin],

  data() {
    let payment_type = PAYMENT_TYPE_PAYMENT;
    if (
      this.salesInvoice.total_remaining_payment < 0 ||
      (this.amountToPay && this.amountToPay) < 0
    ) {
      payment_type = PAYMENT_TYPE_REFUND;
    }
   // console.log("amountToPay => ", this.amountToPay);
   

    return {
      loading: false,
      amount : this.amountToPay,
      paymentMethodsOptions,
      paymentModel: {
        type: "sales-payments",
        relationshipNames: ["invoice", "organization", "creditCard"],
        invoice: this.salesInvoice,
        organization: this.salesInvoice.organization,
        payment_type: payment_type,
        source: null,
        status: PAYMENT_STATUS_COMPLETED,
        amount: Math.abs(
          this.amountToPay
            ? this.amountToPay.toFixed(2)
            : this.salesInvoice.total_remaining_payment.toFixed(2)
        ),
        excerpt: "",
        transactionData: {},
        creditCard: {
          type: "credit-cards",
          id: null,
        },
      },
      PAYMENT_TYPE_PAYMENT,
      PAYMENT_TYPE_REFUND,
      PAYMENT_METHOD_OTHER,
      PAYMENT_METHOD_CHECK,
      PAYMENT_METHOD_CLOVER,
    };
  },

  created() {},

  mounted() {
    
  },

  computed: {

    minAmount (){
    
      if(this.salesInvoice){
          const {pricing,total_remaining_payment} = this.salesInvoice
        
        // console.log("salesInvoice =>", this.salesInvoice);
          if(this.paymentModel.payment_type == PAYMENT_TYPE_REFUND ){
            return Math.abs(total_remaining_payment);
          }
          
       }
       return 1;
    },

    
    maxAmount (){
    
    if(this.salesInvoice){
        const {pricing,total_remaining_payment} = this.salesInvoice
       let amount = pricing.total
      // console.log("salesInvoice =>", this.salesInvoice);
        if(this.paymentModel.payment_type == PAYMENT_TYPE_REFUND ){
            amount = total_remaining_payment;
        }

        return Math.abs(amount);
     }
     return undefined;
  },
  
    canAddPayment() {
      if (this.paymentModel.source === PAYMENT_METHOD_CHECK) {
        return (
          !!this.paymentModel.payment_type &&
          !!this.paymentModel.source &&
          !!this.paymentModel.amount &&
          !!this.paymentModel.transactionData.check_number
        );
      }
      if (this.paymentModel.source === PAYMENT_METHOD_OTHER) {
        return (
          !!this.paymentModel.payment_type &&
          !!this.paymentModel.source &&
          !!this.paymentModel.amount &&
          !!this.paymentModel.excerpt
        );
      }
      return (
        !!this.paymentModel.payment_type &&
        !!this.paymentModel.source &&
        !!this.paymentModel.amount
      );
    },
  },

  methods: {
    closeModal() {
      this.$emit("onCloseModal");
    },

    async handleAddPayment() {
      this.loading = true;
      try {
        const paymentData = cloneDeep(this.paymentModel);
        if (paymentData.source !== PAYMENT_METHOD_CLOVER) {
          delete paymentData.creditCard;
        }

        if (paymentData.payment_type === PAYMENT_TYPE_REFUND) {
          paymentData.amount = -paymentData.amount;
        }
        await this.$store.dispatch("salesPayments/add", paymentData);

        this.$notify({
          type: "success",
          message: this.$t("SALES_PAYMENTS.SALES_PAYMENT_ADDED"),
        });

        this.$emit("paymentAdded");
        this.closeModal();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.setApiValidation(error.response.data.errors);
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    salesInvoice(salesInvoice) {},
  },
};
</script>
