<template>
  <div class="policies-modal">
    <el-button class="policies-modal-link" type="text" @click="open = true">
      {{ $t("POLICIES.POLICIES_FOR_RESERVATION") }}
    </el-button>

    <modal :show.sync="open" lock-scroll="true">
      <template slot="header">
        <h2>{{ $t("POLICIES.CONSULT_POLICIES") }}</h2>
        <button class="custom-close" @click="open = false">
          <i class="fal fa-times"></i>
        </button>
      </template>

      <el-tabs v-model="activeTab" :stretch="true">
        <el-tab-pane
          v-for="tab in policiesTabs"
          :key="tab.name"
          :label="tab.label"
          :name="tab.name"
        >
        </el-tab-pane>
      </el-tabs>

      <component
        :is="currentTab.component.tab || 'div'"
        v-bind="currentTab.component?.getProps?.()"
      >
        <p v-if="!currentTab.component?.tab">
          Contenu des politiques {{ tab.label.toLowerCase() }}...
        </p>
      </component>

      <template slot="footer">
        <el-button @click="open = false" class="cancel">{{
          $t("COMMON.CANCEL")
        }}</el-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { Button, Row, Dialog, Tabs, TabPane } from "element-ui";

import PoliciesViewCancellation from "./Tabs/ViewCancellation.vue";
import PoliciesViewCommunication from "./Tabs/ViewCommunication.vue";
import PoliciesViewBooking from "./Tabs/ViewBooking.vue";
import PoliciesViewPayment from "./Tabs/ViewPayment.vue";
import PoliciesBodyList from "./PoliciesBodyList.vue";

import { PoliciesTypes } from "@/constants/policies";

export default {
  name: "button-view-policies",
  components: {
    [Row.name]: Row,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    PoliciesViewCancellation,
    PoliciesViewCommunication,
    PoliciesViewBooking,
    PoliciesViewPayment,
    PoliciesBodyList,
  },
  props: {
    type: {
      type: String,
      default: "link",
    },
    label: {
      type: String,
      default: "Consulter les politiques",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      open: false, // Dialog ouvert ou fermé
      activeTab: "booking", // Onglet ouvert par défaut
      policiesTabs: [
        {
          label: "Politiques de Réservation",
          name: "booking",
          component: {
            tab: PoliciesViewBooking,
            getProps() {
              return {
                type: PoliciesTypes.BOOKING,
              };
            },
          },
        },
        {
          label: "Politiques d'Annulation",
          name: "cancellation",
          component: {
            tab: PoliciesViewCancellation,
            getProps() {
              return {
                type: PoliciesTypes.CANCELLATION,
              };
            },
          },
        },
        {
          label: "Politiques de Paiements",
          name: "payment",
          component: {
            tab: PoliciesViewPayment,
            getProps() {
              return {
                type: PoliciesTypes.PAYMENTS,
              };
            },
          },
        },
        {
          label: "Politiques de Communication",
          name: "communication",
          component: {
            tab: PoliciesViewCommunication,
            getProps() {
              return {
                type: PoliciesTypes.COMMUNICATION,
              };
            },
          },
        },
      ], // Onglet ouvert par défaut
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },

    currentTab() {
      return this.policiesTabs.find((tab) => tab.name === this.activeTab);
    },
  },
  methods: {
    openDialog() {
      this.model = true;
      this.open = true;
    },
    closeDialog() {
      this.model = false;
      this.open = false;
    },
    handleTabClick(tab) {
      this.activeTab = tab.name;
    },
  },
};
</script>
