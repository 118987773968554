<template>
  <div class="container">
    <span class="resize-loading" v-if="isLoading">
      <span class="loader"></span>
    </span>

    <booking-update-preview
      v-if="bookingHasInvoice && continuedUpdate"
      :invoiceId="bookingData.invoice_id"
      :originalBooking="bookingData"
      :newBooking="booking"
      @onPreviewCanceled="handlePreviewCanceled"
      @onUpdatedSuccess="handleUpdateSuccessFull"
      @onInvoicePreviewed="handleInvoicePreviewed"
      ref="bookingUpdatePreview"
    />

    <div class="container" v-else-if="showSpotsSelector">
      <spots-selector
        v-model="booking.spot_id"
        :filterData="filterData"
        @onSpotSelected="handleSpotSelected"
        @onSelectorClosed="showSpotsSelector = false"
      />
    </div>

    <div class="container" v-else>
      <!-- detail : {{bookingPreviewDetails}} -->
      <div class="booking-details" v-if="btnChangeSpotIsVisible">
        <div class="booking-details-image">
          <img :src="spot.gallery[0]" :alt="spot.spotType.name" />
        </div>
        <div class="booking-details-text">
          <div class="booking-details-text-infos">
            <h3>{{ spot.spotType.name }}</h3>
            <p class="thin">{{ spot.name }} {{ spot.code }}</p>
            <p>{{ booking.code }}</p>
          </div>
          <div class="booking-details-text-change">
            <a
              href="javascript:void(0)"
              v-if="!showSpotsSelector"
              @click="showSpotsSelector = true"
            >
              Changer d'emplacement
            </a>
          </div>
        </div>
      </div>

      <div class="form-wrapper width_1-2">
        <!-- date de reservation -->
        <base-input :label="$t('BOOKINGS.BOOKING_DATE')">
          <el-date-picker
            v-model="selectedDateRange"
            type="daterange"
            start-placeholder="Date de début"
            end-placeholder="Date de fin"
            format="dd MMM yyyy"
            value-format="yyyy-MM-dd"
            :min="new Date()"
            :max="$moment().add(maxMonthsCalendar, 'months').toDate()"
            :clearable="false"
            :picker-options="{
              disabledDate: disabledDates,
              cellClassName: dateCellClassName,
            }"
            @change="onDateSelected"
          />
        </base-input>
        <validation-error
          v-if="!isDateRangeValid && !loadingCalendar"
          :errors="[$t('BOOKINGS.NOT_AVAILABLE_ERROR')]"
        />
        <validation-error :errors="apiValidationErrors.start_at" />
        <validation-error
          v-if="!isPricingValid && !loadingPricing"
          :errors="[$t('BOOKINGS.INVALID_PRICING_FOR_DATE_RANGE')]"
        />
      </div>

      <!-- TODO Arrèter de retirer ce champs il est important -->
      <div class="form-wrapper width_1-2">
        <base-input
          :label="`${$t('SPOTS.CHANGE_RATES')}`"
          :placeholder="$t('SPOTS.CHANGE_RATES')"
          v-model="booking.pricing_custom"
          @change="onPricingCustomChanged"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.pricing_custom" />
      </div>

      <!-- region Occupants du camping -->
      <div class="form-wrapper width_1">
        <div class="form-wrapper-item width_1-3">
          <!-- adult capacity -->
          <base-input :label="$t('SPOTS.SPOT_ADULT_CAPACITY')">
            <el-select
              :label="$t('SPOTS.SPOT_ADULT_CAPACITY')"
              :placeholder="$t('SPOTS.SPOT_ADULT_CAPACITY')"
              v-model="booking.adults_count"
              @change="onAdultCapacityChanged"
            >
              <el-option
                v-for="n in Array.from({ length: maxAdults }, (_, i) => i + 1)"
                :key="n"
                :value="n"
                :label="n"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.adults_count" />
        </div>

        <!-- children capacity -->
        <div class="form-wrapper-item width_1-3">
          <base-input :label="$t('SPOTS.SPOT_CHILDREN_CAPACITY')">
            <el-select
              :label="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
              :placeholder="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
              v-model="booking.children_count"
              @change="onFormChanged"
            >
              <el-option
                v-for="n in maxChildren + 1"
                :key="n"
                :value="n - 1"
                :label="n - 1"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.children_count" />
        </div>

        <!-- pets capacity -->
        <div class="form-wrapper-item width_1-3">
          <base-input :label="$t('SPOTS.SPOT_PETS_CAPACITY')">
            <el-select
              :label="$t('SPOTS.SPOT_PETS_CAPACITY')"
              :placeholder="$t('SPOTS.SPOT_PETS_CAPACITY')"
              v-model="booking.pets_count"
              @change="onFormChanged"
            >
              <el-option
                v-for="n in maxPets + 1"
                :key="n"
                :value="n - 1"
                :label="n - 1"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.pets_count" />
        </div>
      </div>
      <!-- endregion -->

      <!-- region Lits -->
      <div
        class="form-wrapper width_1-2"
        v-if="shouldIDisplayBedsQuantityField"
      >
        <base-input :label="`${$t('SPOTS.BEDS_TOTAL_QTY')}`">
          <el-select
            :label="$t('SPOTS.BEDS_TOTAL_QTY')"
            :placeholder="$t('SPOTS.BEDS_TOTAL_QTY')"
            v-model="booking.beds_qty"
            @change="onFormChanged"
          >
            <el-option
              v-for="n in spot.beds_total_qty + 1"
              :key="n"
              :value="n - 1"
              :label="n - 1"
            />
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.beds_qty" />
      </div>
      <!-- endregion -->

      <!-- region Equipements -->
      <div class="form-wrapper width_1-2">
        <!-- Equipments -->
        <base-input
          :label="$t('SPOTS.EQUIPMENT_SELECTION')"
          v-if="isLandOrSeasonalFormType"
        >
          <allowed-equipment-selector
            :value="booking.equipment"
            :options-value="spot.allowed_equipment"
            @valueChanged="(value) => (booking.equipment = value)"
            allowNone
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.equipment" />
      </div>

      <div class="form-wrapper width_1-2">
        <!-- Equipments length -->
        <base-input
          type="text"
          :min="0"
          :max="maxEquipmentLength"
          :label="$t('SPOTS.EQUIPMENT_LENGTH')"
          :placeholder="$t('SPOTS.EQUIPMENT_LENGTH')"
          :name="`'${$t('SPOTS.EQUIPMENT_LENGTH')}'`"
          :rules="equipmentLengthValidationRules"
          v-model="booking.equipment_length"
          @change="onFormChanged"
          v-if="
            isLandOrSeasonalFormType &&
            booking.equipment &&
            booking.equipment !== TENT
          "
        >
          <template #label>
            <label class="form-control-label" for="">
              {{ $t("SPOTS.EQUIPMENT_LENGTH") }}
              <span v-if="maxEquipmentLength" class="h5 text-muted font-italic">
                ({{
                  $t("SPOTS.MAX_LENGTH", { length: maxEquipmentLength ?? 0 })
                }})
              </span>
            </label>
          </template>
        </base-input>
        <validation-error :errors="apiValidationErrors.equipment_length" />
      </div>
      <!-- endregion -->

      <!-- region Extensions -->
      <!-- Passager -->
      <div class="form-wrapper width_1-2">
        <base-input
          :label="$t('SPOTS.PASSENGER_EXTENSION')"
          v-if="isLandOrSeasonalFormType"
        >
          <el-select
            :label="$t('SPOTS.PASSENGER_EXTENSION')"
            :placeholder="$t('SPOTS.PASSENGER_EXTENSION')"
            v-model="booking.passenger_extensions"
            @change="onFormChanged"
          >
            <el-option
              v-for="n in spot.passenger_extensions + 1"
              :key="n"
              :value="n - 1"
              :label="n - 1"
            />
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.passenger_extensions" />
      </div>

      <!-- Conducteur -->
      <div class="form-wrapper width_1-2">
        <base-input
          :label="$t('SPOTS.DRIVER_EXTENSION')"
          v-if="isLandOrSeasonalFormType"
        >
          <el-select
            :label="$t('SPOTS.DRIVER_EXTENSION')"
            :placeholder="$t('SPOTS.DRIVER_EXTENSION')"
            v-model="booking.driver_extensions"
            @change="onFormChanged"
          >
            <el-option
              v-for="n in spot.driver_extensions + 1"
              :key="n"
              :value="n - 1"
              :label="n - 1"
            />
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.rate" />
      </div>
      <!-- endregion -->

      <!-- region Services inclus et électricité -->
      <!-- Services inclus: should not be rendered -->
      <div
        class="form-wrapper width_1-2"
        v-if="isLandOrSeasonalFormType && false"
      >
        <base-input :label="$t('SPOTS.SERVICES_INCLUDED')">
          <service-included-selector
            :options-value="spot.services_included"
            :value="booking.services_included"
            @valueChanged="(value) => (booking.services_included = value)"
            allow-none
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.services_included" />
      </div>

      <!-- Electricity -->
      <div class="form-wrapper width_1-2" v-if="isLandOrSeasonalFormType">
        <base-input :label="$t('SPOTS.ELECTRICITY')">
          <electricity-selector
            :options-value="spot.electricity"
            :value="booking.electricity"
            @valueChanged="(value) => (booking.electricity = value)"
            allow-none
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.electricity" />
      </div>
      <!-- endregion -->

      <!-- region Notes supps -->
      <!-- Demande client -->
      <div class="form-wrapper width_1-2">
        <base-input
          :label="$t('BOOKINGS.SPECIAL_REQUEST')"
          :placeholder="$t('BOOKINGS.SPECIAL_REQUEST')"
        >
          <el-input
            type="textarea"
            v-model="booking.special_request"
          ></el-input>
        </base-input>
        <validation-error :errors="apiValidationErrors.special_request" />
      </div>

      <!-- Note interne -->
      <div class="form-wrapper width_1-2">
        <base-input
          :label="$t('BOOKINGS.INTERNAL_NOTE')"
          :placeholder="$t('BOOKINGS.INTERNAL_NOTE')"
        >
          <el-input type="textarea" v-model="booking.comment"></el-input>
        </base-input>
        <validation-error :errors="apiValidationErrors.comment" />
      </div>
      <!-- endregion -->

      <!-- region blocage de l'emplacement -->
      <div class="form-wrapper width_1-2">
        <base-input :label="$t('BOOKINGS.BLOCK_THIS_SPOT')">
          <div class="radio-group-wrapper">
            <el-radio-group v-model="booking.is_spot_blocked">
              <el-radio :label="false">
                {{ $t("COMMON.NO") }}
              </el-radio>
              <el-radio :label="true">
                {{ $t("COMMON.YES") }}
              </el-radio>
            </el-radio-group>
          </div>
        </base-input>
      </div>
      <!-- endregion -->

      <!-- region La facture ici -->

      <!--  <div v-if="this.booking" class="invoice mb-5">
       
        <div class="invoice-title">
          <h3>{{ $t("APPS.INVOICING_MODULE") }}</h3>
        </div>

        <div class="invoice-list" v-if="pricing && pricing.total">
          <dl>
            <dt>
              {{ $t("BOOKINGS.TITLE") }}
              {{ booking.code }}
            </dt>
            <dd>{{ $formatCurrency(pricing.total_nights) }}</dd>
          </dl>
          <div class="invoice-list" v-if="pricing.package_price">
            <dl class="row">
              <dt>
                {{ pricing.package_price_name }}
              </dt>
              <dd>{{ $formatCurrency(pricing.package_price) }}</dd>
            </dl>
          </div>
          <div class="invoice-subtotal">
            <dl>
              <dt>
                {{ $t("COMMON.SUBTOTAL") }}
              </dt>
              <dd>{{ $formatCurrency(pricing.sub_total) }}</dd>
            </dl>
          </div>
          <div class="invoice-others">
            <dl v-for="(tax, index) in pricing.taxes.details" :key="index">
              <dt>{{ tax.name }}</dt>
              <dd>{{ $formatCurrency(tax.amount) }} CAD</dd>
            </dl>
          </div>
          <div class="invoice-subtotal">
            <dl>
              <dt>{{ $t("COMMON.TOTAL_TAXES") }}</dt>
              <dd>{{ $formatCurrency(pricing.taxes.total) }}</dd>
            </dl>
          </div>
          <div class="invoice-total">
            <dl>
              <dt>
                {{ $t("COMMON.TOTAL") }}
              </dt>
              <dd>
                <span class="difference" v-if="totalDifference != 0">
                  ( {{ totalDifference >= 0 ? "+" : "-"
                  }}{{ $formatCurrency(Math.abs(totalDifference)) }} )
                </span>
                {{ $formatCurrency(pricing.total) }}
              </dd>
            </dl>
          </div>
        </div>
      </div> -->

      <!-- region action button -->
      <div class="buttons">
        <el-button
          class="cancel"
          type="default"
          @click="onBookingEditionCancelled"
        >
          {{ $t("COMMON.CANCEL") }}
        </el-button>

        <el-button
          class="submit"
          type="primary"
          @click="handleSubmit"
          :loading="isLoading || loadingPricing || loadingCalendar"
          :disabled="
            !isDateRangeValid ||
            !isPricingValid ||
            loadingPricing ||
            loadingCalendar
          "
        >
          {{ $t("COMMON.UPDATE_ITEM") }}
        </el-button>
      </div>
      <!-- endregion -->
    </div>
  </div>
</template>

<script>
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Header,
  Image,
  Row,
  Step,
  Steps,
  Select,
  Option,
  Radio,
  RadioGroup,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import AllowedEquipmentSelector from "@/components/AllowedEquipmentSelector.vue";
import {
  BOOKING_FORM_TYPE_LAND,
  BOOKING_FORM_TYPE_PROPERTY,
  BOOKING_FORM_TYPE_SEASONAL,
} from "@/constants/spotCategories";
import { TENT } from "@/constants/allowedEquipments";
import ElectricitySelector from "@/components/ElectricitySelector.vue";
import ServiceIncludedSelector from "@/components/ServiceIncludedSelector.vue";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import moment from "moment";
import { mapGetters } from "vuex";
import SpotsSelector from "../components/SpotsSelector.vue";
import BookingUpdatePreview from "./BookingUpdatePreview.vue";
import Jsona from "jsona";
import { float } from "quill/ui/icons";

export default {
  name: "EditBookingForm",

  components: {
    ServiceIncludedSelector,
    ElectricitySelector,
    AllowedEquipmentSelector,
    BookingUpdatePreview,
    ValidationError,
    [DatePicker.name]: DatePicker,
    [Header.name]: Header,
    [Divider.name]: Divider,
    [Row.name]: Row,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    SpotsSelector,
  },

  mixins: [formMixin],

  props: {
    bookingData: {
      type: Object,
      required: true,
    },
  },

  data() {
    let selectedDateRange = [
      this.bookingData.start_at,
      this.bookingData.end_at,
    ];

    const spot = { ...this.bookingData.spot };
    const booking = { ...this.bookingData };
    delete booking.spot;

    const maxAdults = spot.adults_capacity;
    const maxChildren = spot.children_capacity;
    const maxPets = spot.pets_capacity;

    const maxEquipmentLength = this.bookingData.spot.equipment_length ?? 0;
    let equipmentLengthValidationRules = `numeric|min_value:1|max_value:${maxEquipmentLength}`;
    if (maxEquipmentLength === 0) {
      equipmentLengthValidationRules = "numeric|min_value:1";
    }

    return {
      spot,
      booking,
      maxAdults: maxAdults,
      maxChildren: maxChildren,
      maxPets: maxPets,
      selectedDateRange: selectedDateRange,
      equipmentLengthValidationRules: equipmentLengthValidationRules,
      maxEquipmentLength,
      formErrors: null,
      BOOKING_FORM_TYPE_LAND,
      BOOKING_FORM_TYPE_PROPERTY,
      TENT,
      isLoading: false,
      loadingPricing: true,
      loadingCalendar: true,
      bookingPricingChanged: false,
      continuedUpdate: false,
      showSpotsSelector: false,
      bookingPreviewDetails: null,
    };
  },

  async created() {},

  mounted() {
    this.getCalendar();
    this.getPricingDebounced();
  },

  computed: {
    ...mapGetters("spots", { calendar: "calendar" }),
    ...mapGetters("bookings", { pricing: "pricing" }),

    shouldIDisplayBedsQuantityField() {
      return false;
      // this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_LAND &&
      // this.spot.category.booking_form_type !==
      //   BOOKING_FORM_TYPE_READY_TO_CAMP &&
      // this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_SEASONAL
    },
    isSeasonalFormType() {
      return (
        this.spot.category.booking_form_type === BOOKING_FORM_TYPE_SEASONAL
      );
    },
    isLandFormType() {
      return this.spot.category.booking_form_type === BOOKING_FORM_TYPE_LAND;
    },
    isLandOrSeasonalFormType() {
      return this.isLandFormType || this.isSeasonalFormType;
    },
    isDateRangeValid() {
      const start = moment(this.booking.start_at);
      const end = moment(this.booking.end_at);

      let isAvailable = false;
      if (this.calendar?.booked_nights) {
        let current = start.clone();
        while (current.isBefore(end)) {
          if (
            this.calendar.booked_nights.includes(current.format("YYYY-MM-DD"))
          ) {
            return false;
          }
          current.add(1, "days");
        }
        isAvailable = true;
      }
      return (
        this.booking.start_at &&
        this.booking.end_at &&
        this.booking.start_at < this.booking.end_at &&
        isAvailable
      );
    },
    isPricingValid() {
      if (!this.booking.start_at || !this.booking.end_at) return false;
      const nights = moment(this.booking.end_at).diff(
        moment(this.booking.start_at),
        "days"
      );
      return this.pricing?.total > 0 && nights > 0;
    },

    totalDifference() {
      return this.pricing.total - this.booking.pricing_data.total;
    },

    filterData() {
      const { start_at, end_at, adults_count, children_count, pets_count } =
        this.booking;

      return {
        availability_date_range: [
          this.$convertDateToUtc(start_at),
          this.$convertDateToUtc(end_at),
        ],
        adults_capacity: adults_count,
        children_capacity: children_count,
        pets_capacity: pets_count,
      };
    },

    maxMonthsCalendar() {
      return process.env.VUE_APP_SPOT_CALENDAR_MONTHS_LIMIT ?? 18;
    },

    btnChangeSpotIsVisible() {
      return process.env.VUE_APP_CHANGE_SPOT_BUTTON_ENABLED ?? true;
    },
    bookingHasInvoice() {
      return this.bookingData.invoice_id !== null;
    },
  },

  methods: {
    onFormChanged() {
      this.$emit("onFormChanged", this.booking);
      this.loadInvoicePreview();
    },
    onPricingCustomChanged(price) {
      if(this.booking.pricing_custom) {
        this.booking.pricing_custom = parseFloat(this.booking.pricing_custom.replace(',', '.'));
      }
      this.bookingPricingChanged = true;
      this.onFormChanged();
    },
    onAdultCapacityChanged(capacity) {
      this.booking.adults_count = capacity;
      this.bookingPricingChanged = true;
      this.onFormChanged();
    },
    onBookingEditionCancelled() {
      this.$emit("onBookingEditionCancelled");
    },
    onDateSelected(range) {
      this.booking.start_at = range[0];
      this.booking.end_at = range[1];
      this.bookingPricingChanged = true;
      this.onFormChanged();
    },

    /**
     * Mettre a 0 l'heure d'une date avec heure.
     *
     * @param {Date} date
     * @returns {Date}
     */
    setDateTimeComponentToZero(date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0, 0);

      return date;
    },

    async handleSubmit() {
      if (this.bookingHasInvoice && this.bookingPricingChanged) {
        return this.loadPreviewUpdate();
      }

      if (this.pricing.total <= 0) {
        this.$notify({
          type: "danger",
          message: this.$t("SPOTS.NO_PRICING_FOUND_FOR_SPOT"),
        });

        swal.close();
        return;
      }

      const confirmation = await swal.fire({
        text: this.$t("BOOKINGS.UPDATE_BOOKING"),
        type: "question",
        customClass: {
          popup: "default-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }
      await this.sendInformation();
    },

    async sendInformation() {
      this.isLoading = true;
      const bookingData = cloneDeep(this.booking);
      delete bookingData.check_in_at;
      delete bookingData.check_out_at;
      delete bookingData.confirmation_expires_at;

      bookingData.start_at = this.$convertDateToUtc(this.booking.start_at);
      bookingData.end_at = this.$convertDateToUtc(this.booking.end_at);

      try {
        await this.$store.dispatch("bookings/update", bookingData);

        this.$notify({
          type: "success",
          message: this.$t("BOOKINGS.BOOKING_UPDATED"),
        });

        const booking = await this.$store.getters["bookings/booking"];

        this.$emit("onBookingEditionDone", booking, true);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.formErrors = error.response.data.errors;
      } finally {
        this.isLoading = false;
      }
    },

    setModal(config) {
      config = {
        ...{
          title: null,
          html: null,
          type: "warning",
          customClass: {
            popup: "delete-popup",
          },
          buttonsStyling: false,
          showCancelButton: false,
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonText: this.$t("COMMON.CLOSE"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        },
        ...config,
      };
      return swal.fire(config);
    },

    dateCellClassName(date) {
      if (!this.calendar) {
        return "disabled";
      }
      const formatedDate = moment(date).format("YYYY-MM-DD");
      if (this.calendar.booked_nights.includes(formatedDate)) {
        return "booked";
      }
      if (this.calendar.booking_end_dates.includes(formatedDate)) {
        return "end";
      }
      if (this.calendar.booking_start_dates.includes(formatedDate)) {
        return "start";
      }
      return "avaibale";
    },

    disabledDates(date) {
      const momentDate = moment(date);
      const formatedDate = momentDate.format("YYYY-MM-DD");
      if (this.calendar.booked_nights.includes(formatedDate)) {
        return true;
      }
      return (
        momentDate.isBefore(moment().startOf("day")) ||
        momentDate.isAfter(
          moment().add(this.maxMonthsCalendar, "months").startOf("day")
        )
      );
    },

    async getCalendar() {
      if (this.spot.id) {
        this.loadingCalendar = true;
        await this.$store.dispatch("spots/getCalendar", {
          id: this.spot.id,
          bookingId: this.booking.id,
        });
        this.loadingCalendar = false;
      }
    },

    getPricingDebounced: _.debounce(function () {
      this.getPricing();
    }, 300),

    async getPricing() {
      if (this.spot.id) {
        this.loadingPricing = true;
        await this.$store.dispatch("bookings/getPricing", {
          start_at: this.booking.start_at,
          end_at: this.booking.end_at,
          spot_id: this.spot.id,
          adults_count: this.booking.adults_count,
          pricing_custom: this.booking.pricing_custom,
          organization_id: this.booking.organization_id,
        });
        this.loadingPricing = false;
      }
    },

    handleSpotSelected(newSpot) {
      console.log("handleSpotSelected => ", newSpot);
      if (newSpot) {
        this.spot = newSpot;
        this.booking = {
          ...this.booking,
          ...{
            // spot_id : newSpot.id,
            spot: newSpot,
          },
        };

        this.bookingPricingChanged = true;
      }

      this.showSpotsSelector = false;
    },

    handleSpotsListLoading({ loading }) {
      this.isLoading = loading;
    },

    handlePreviewCanceled() {
      this.continuedUpdate = false;
    },
    handleUpdateSuccessFull(newInvoice) {
      this.continuedUpdate = false;
      this.isLoading = true;
      if (newInvoice) {
        // console.clear();
        console.log("newInvoice => ", newInvoice);

        const bookingId = this.booking.id;
        console.log("bookingId => ", this.booking.id);

        const invoiceItem = newInvoice.itemsBookings.find(
          (item) => item.salesInvoiceable.from_booking_id == bookingId
        );
        if (invoiceItem) {
          this.booking = invoiceItem.salesInvoiceable;
        }
        console.log("this.booking => ", this.booking);
        //  this.$emit("onFormChanged", this.booking);
        this.$emit("onBookingEditionDone", this.booking, true);
        this.isLoading = false;
      }
    },
    handleInvoicePreviewed(previewDetails) {
      // console.log("previewDetails => ",previewDetails)
    },
    loadPreviewUpdate() {
      this.continuedUpdate = true;
    },

    async loadInvoicePreview() {
      try {
        const invoiceId = this.booking.invoice_id;

        let booking = JSON.parse(JSON.stringify(this.booking));

        const payload = {
          newBooking: booking,
        };
        const invoice = await this.$store.dispatch(
          "salesInvoices/previewFromUpdatedBooking",
          { invoiceId, payload }
        );
        // console.clear();

        console.log('Edit', payload);
        console.info("bookingPreviewDetails => ", invoice);
        this.bookingPreviewDetails =
          invoice?.preview_details.booking_updated_details;
      } catch (error) {
        console.error("booking.loadPreviewUpdate => ", error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
      }
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },

    booking: {
      handler: function (booking) {
        this.getPricingDebounced();
        this.getCalendar();
      },
      deep: true,
    },

    showSpotsSelector: {
      handler: function (newVal) {
        if (newVal) {
        }
      },
      deep: true,
    },

    bookingPricingChanged: {
      handler: function (newVal) {
        // console.log('bookingPricingChanged => ', newVal);
        // this.$refs.bookingUpdatePreview.loadPreviewUpdate();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.difference {
  font-size: 0.6em;
  color: green;
}
</style>
