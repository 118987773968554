<template>
  <div class="section">
    <dl class="row">
      <dt>
        {{ $formatCurrency(item.unit_price) }} ( x
        {{ $t("COMMON.UNIT_DAYS", { days: item.quantity }) }} )
      </dt>
      <dd>{{ $formatCurrency(item.pricing.subtotal) }}</dd>
    </dl>

    <!-- region autre frais -->
    <div v-if="item.salesOrder.itemsOthersFees">
      <dl
        class="row"
        v-for="(fee, key) in item.salesOrder.itemsOthersFees"
        :key="key"
      >
        <dt>
          {{ fee.excerpt }}
        </dt>
        <dd>{{ $formatCurrency(fee.unit_price) }}</dd>
      </dl>
    </div>
    <!-- endregion -->
    <dl class="row subtotal">
      <dt>{{ $t("COMMON.SUBTOTAL") }}</dt>
      <dd>
        {{ $formatCurrency(item.pricing.subtotal + itemsOthersFeesTotal) }}
      </dd>
    </dl>
  </div>
</template>
<script>
export default {
  name: "order-item-summary",
  components: {},
  props: {
    item: {
      type: Object,
      default: null,
      description: "SalesOrderItem object",
    },
    showTaxes: {
      type: Boolean,
      default: true,
      description: "Show taxes",
    },
  },
  data() {
    return {};
  },

  computed: {
    itemsOthersFeesTotal() {
      return this.item.salesOrder.itemsOthersFees.reduce(
        (acc, fee) => acc + fee.unit_price,
        0
      );
    },
  },
};
</script>
