<template>
  <div class="order-item__left-inner">
    <div class="order-item__left-inner-carousel">
      <el-carousel :autoplay="false" arrow="never" indicator-position="inside">
        <el-carousel-item v-for="img in booking.spot.gallery" :key="img">
          <img :src="img" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="order-item__left-inner-date">
      <div class="item">
        <h4>{{ $t("BOOKINGS.BOOKING_DATE") }}</h4>
        <p>
          {{ $formatDate(booking.start_at, "ll") }} -
          {{ $formatDate(booking.end_at, "ll") }}
        </p>
        <h3>{{ booking.spot.spotType.name }}</h3>
        <p>
          {{ booking.spot.code }}
        </p>
        <p class="status" v-if="booking.status === STATUS_DRAFT">
          <el-tag>
            <img src="/img/kw-loading.svg" alt="icon" />
          </el-tag>
          <span>
            {{ minutesAndSeconds }}
          </span>
        </p>
      </div>
      <div class="item">
        <h4>{{ $t("BOOKINGS.BOOKING_DETAILS") }}</h4>
        <p>
          <span>
            {{ $t("BOOKINGS.CAPACITY") }}:
            {{ booking.adults_count + booking.children_count }}
          </span>
          <br />
        </p>
        <p
          v-if="
            booking.spot.category.booking_form_type === BOOKING_FORM_TYPE_LAND
          "
        >
          <span v-if="booking.beds_qty">
            {{ $t("BOOKINGS.BEDS_QTY") }}: {{ booking.beds_qty }}
            <br />
          </span>
          <span v-if="booking.rooms_qty">
            {{ $t("BOOKINGS.ROOMS_QTY") }}: {{ booking.rooms_qty }}
            <br />
          </span>
        </p>
        <p
          v-if="
            booking.spot.category.booking_form_type ===
            BOOKING_FORM_TYPE_PROPERTY
          "
        >
          <span v-if="booking.equipment">
            {{ $t("BOOKINGS.SELECTED_EQUIPMENT") }}: {{ booking.equipment }}
            <br />
          </span>
          <span v-if="booking.equipment_length">
            {{ $t("SPOTS.EQUIPMENT_LENGTH") }}: {{ booking.equipment_length }}
            <br />
          </span>
          <span v-if="booking.driver_extensions">
            {{ $t("SPOTS.DRIVER_EXTENSION") }}: {{ booking.driver_extensions }}
            <br />
          </span>
          <span v-if="booking.passenger_extensions">
            {{ $t("SPOTS.PASSENGER_EXTENSION") }}:
            {{ booking.passenger_extensions }}
            <br />
          </span>
          <span v-if="booking.electricity">
            {{ $t("SPOTS.ELECTRICITY") }}: {{ booking.electricity }}
            <br />
          </span>
        </p>
        <p v-if="booking.special_request">
          {{ $t("BOOKINGS.SPECIAL_REQUEST") }}:
          <span v-html="booking.special_request"></span>
        </p>
      </div>
    </div>
    <div class="order-item__left-inner-summary">
      <el-button
        v-if="canBeEdited"
        type="text"
        @click="() => editBooking(booking)"
      >
        {{ $t("COMMON.UPDATE") }}
      </el-button>
      <!-- region affichage des frais -->
      <order-item-summary :showTaxes="false" :item="orderItem" />
      <!-- endregion -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Button, Carousel, CarouselItem, Tag } from "element-ui";
import OrderItemSummary from "./OrderItemSummary.vue";
import {
  BOOKING_FORM_TYPE_LAND,
  BOOKING_FORM_TYPE_PROPERTY,
} from "@/constants/spotCategories";

import {
  STATUS_ARRIVED,
  STATUS_DRAFT,
  STATUS_GUARANTEED,
  STATUS_NOT_GUARANTEED,
} from "@/constants/bookings";

const delay = parseInt(process.env.VUE_APP_CART_DEFAULT_DELAY) * 60;

export default {
  name: "booking-card",

  components: {
    OrderItemSummary,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Tag.name]: Tag,
    [Button.name]: Button,
  },

  props: {
    orderItem: {
      type: Object,
      default: null,
      description: "orderItem object",
    },
    booking: {
      type: Object,
      default: null,
      description: "Booking object",
    },
  },

  data() {
    return {
      BOOKING_FORM_TYPE_LAND,
      BOOKING_FORM_TYPE_PROPERTY,
      STATUS_DRAFT,
      STATUS_GUARANTEED,
      STATUS_NOT_GUARANTEED,
      STATUS_ARRIVED,
      minute: 0,
      expirations: [10, 5, 0],
      notifiedMinutes: [],
    };
  },

  mounted() {
    setInterval(this.computeTempsRestant, 100);
  },

  beforeDestroy() {
    clearInterval(this.computeTempsRestant);
  },

  computed: {
    minutesAndSeconds() {
      let minutes = Math.floor(this.minute / 60);
      let secondes_restantes = this.minute % 60;

      return this.$t("COMMON.MINUTES_REMAINING", [
        minutes,
        secondes_restantes < 10 ? `0${secondes_restantes}` : secondes_restantes,
      ]);
    },

    canBeEdited() {
      const allowedStatus = [
        STATUS_DRAFT,
        STATUS_GUARANTEED,
        STATUS_NOT_GUARANTEED,
        STATUS_ARRIVED,
      ];
      const bookingStatus = this.booking.status;
      const hasCustomer = this.orderItem.salesOrder.recipient;

      return (
        (allowedStatus.includes(bookingStatus) &&
          this.$currentUserCan(this.$permissions.PERM_EDIT_BOOKINGS)) ||
        !hasCustomer
      );
    },
  },

  methods: {
    editBooking(booking) {
      this.$emit("onEditBooking", booking);
    },
    computeTempsRestant() {
      try {
        const blockedAtMoment = moment.utc(this.booking.updated_at);
        const nowMoment = moment.utc();
        this.minute = delay - nowMoment.diff(blockedAtMoment, "seconds");
      } catch (e) {}
    },
  },
};
</script>
