<template>
  <div class="tab-container">
    <!-- <h3>{{ title }}</h3> -->

    <div class="description">
      <ol class="space-y-1">
        <li v-for="policy in policies" :key="policy.id">
          <div v-html="policy.description"></div>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import PoliciesBodyList from "@/views/Pages/PolicyModule/PolicyManagement/components/PoliciesBodyList.vue";
import { PoliciesTypes } from "@/constants/policies";

export default {
  name: "policies-view-booking",

  components: {
    PoliciesBodyList,
  },
  props: {},
  data() {
    return {
      policies: [
        {
          id: 1,
          description:
            "Toute réservation peut être effectuée en ligne, par téléphone ou directement à la reception",
        },
        {
          id: 2,
          description:
            "Un email de confirmation est envoyé après validation de la réservation",
        },
        {
          id: 3,
          description:
            "La réservation est nominative et ne peut $etre transférée sans accord préalable",
        },
        {
          id: 4,
          description:
            "Un age minimum de [XX] ans est requis pour effectuer une reservation.",
        },
        {
          id: 5,
          description:
            "Les emplacements et hébergements sont attribués selon les disponibilités et préférences indiquées",
        },
      ],
    };
  },

  mounted() {},
  updated() {},

  computed: {},

  methods: {},
};
</script>
<style></style>
