<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-text mt-3">
        <h4 class="title-red">{{ $t("COMMON.BOOKING") }}</h4>
        <!-- region booking -->
        <!-- booking number -->
        <dl class="row">
          <dt>
            {{ $t("BOOKINGS.BOOKING_NUMBER") }}
          </dt>
          <dd>
            {{ booking.code }}
          </dd>
        </dl>
        <!-- status -->
        <dl class="row">
          <dt>
            {{ $t("COMMON.STATUS") }}
          </dt>
          <dd>
            {{ $t(`BOOKINGS.${booking.status}`) }}
          </dd>
        </dl>
        <!-- facture -->
        <dl class="row" v-if="this.salesInvoice">
          <dt>
            {{ $t("SALES_INVOICES.SALES_INVOICE") }}
          </dt>
          <dd v-if="$currentUserCan($permissions.PERM_VIEW_SALES_INVOICES)">
            <object-link :object="salesInvoice" />
          </dd>
          <dd v-else>
            {{ salesInvoice.code }}
          </dd>
        </dl>
        <!-- Faire payer la difference ou rembourser -->
        <dl class="row" v-if="salesInvoice.total_remaining_payment != 0">
          <dt class="font-weight-bold">
            {{ $t("SALES_PAYMENTS.VIEW_SALES_PAYMENT") }}
          </dt>
          <dd>
            <el-button
              @click="onPaymentBtnClick"
              :disabled="paymentProcessing"
              class="difference"
            >
              <span v-if="salesInvoice.total_remaining_payment > 0">
                {{
                  $t("SALES_INVOICES.PAIEMENT_DUE_LABEL", {
                    amount: $formatCurrency(
                      salesInvoice.total_remaining_payment
                    ),
                  })
                }}
              </span>
              <span v-else>
                {{
                  $t("SALES_INVOICES.REFUND_LABEL", {
                    amount: $formatCurrency(
                      salesInvoice.total_remaining_payment
                    ),
                  })
                }}
              </span>
            </el-button>
          </dd>
          <!-- endregion Formulaire de paiement -->
          <sales-invoice-view-add-payment-modal
            v-if="salesInvoice && paymentStep"
            :salesInvoice="salesInvoice"
            :showModal="paymentStep"
            :amount-to-pay="salesInvoice.amount_difference"
            @onCloseModal="onPaymentCancelled"
            @paymentAdded="onChargeDifferenceDone"
          />
          <!-- endregion -->
        </dl>
        <dl class="row">
          <dt>
            {{ $t("BOOKINGS.ARRIVING_AT") }}
          </dt>
          <dd v-if="booking.check_in_at">
            {{ $formatDate(booking.check_in_at, "LL") }}
          </dd>
          <dd v-else>
            {{ $t("COMMON.PLANNED") }} -
            {{ $formatDate(booking.start_at, "LL") }}
          </dd>
        </dl>
        <dl class="row">
          <dt>
            {{ $t("BOOKINGS.LEAVING_AT") }}
          </dt>
          <dd v-if="booking.check_out_at">
            {{ $formatDate(booking.check_out_at, "LL") }}
          </dd>
          <dd v-else>
            {{ $t("COMMON.PLANNED") }} -
            {{ $formatDate(booking.end_at, "LL") }}
          </dd>
        </dl>

        <!-- region customer -->
        <h4 class="title-red">{{ $t("COMMON.CUSTOMER") }}</h4>

        <dl v-if="booking.customer" class="row">
          <dt>
            {{ $t("CUSTOMERS.LAST_NAME") }}
          </dt>
          <dd>
            {{ booking.customer.lastname }} {{ booking.customer.firstname }}
          </dd>
        </dl>

        <dl v-if="booking.customer" class="row">
          <dt>
            {{ $t("CUSTOMERS.EMAIL") }}
          </dt>
          <dd>
            {{ booking.customer.email }}
          </dd>
        </dl>

        <dl v-if="booking.customer" class="row">
          <dt>
            {{ $t("CUSTOMERS.PHONE") }}
          </dt>
          <dd>
            {{ booking.customer.phone }}
          </dd>
        </dl>
        <!-- endregion -->

        <!-- region emplacement -->
        <h4 class="title-red">{{ $t("COMMON.SPOT") }}</h4>

        <dl class="row">
          <dt>
            {{ $t("SPOTS.SPOT_NAME") }}
          </dt>
          <dd>
            {{ booking.spot.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("SPOTS.SPOT_NUMBER") }}
          </dt>
          <dd>
            {{ booking.spot.code }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.CATEGORY") }}
          </dt>
          <dd>
            {{ booking.spot.category?.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("SPOTS.SPOT_TYPE") }}
          </dt>
          <dd>
            {{ booking.spot.spotType?.name }}
          </dd>
        </dl>

        <!-- equipement -->
        <dl class="row" v-if="isLandSpotType && booking.equipment">
          <dt>
            {{ $t("SPOTS.ALLOWED_EQUIPMENT") }}
          </dt>
          <dd>
            {{ $t(`SPOTS.ALLOWED_EQUIPMENT_${booking.equipment}`) }}
          </dd>
        </dl>

        <!-- electricity -->
        <dl class="row" v-if="isLandSpotType && booking.electricity">
          <dt>
            {{ $t("SPOTS.ELECTRICITY") }}
          </dt>
          <dd>
            {{ booking.electricity }}
          </dd>
        </dl>

        <!-- driver_extensions -->
        <div class="row" v-if="isLandSpotType && booking.driver_extensions">
          <dl>
            <dt>
              {{ $t("SPOTS.DRIVER_EXTENSIONS") }}
            </dt>
            <dd>
              {{ booking.driver_extensions }}
            </dd>
          </dl>
        </div>

        <!-- passenger_extensions -->
        <dl class="row" v-if="isLandSpotType && booking.passenger_extensions">
          <dt>
            {{ $t("SPOTS.PASSENGER_EXTENSIONS") }}
          </dt>
          <dd>
            {{ booking.passenger_extensions }}
          </dd>
        </dl>

        <!-- extensions -->
        <dl class="row" v-if="false">
          <dt>
            {{ $t("BOOKINGS.SERVICE") }}
          </dt>
          <dd></dd>
        </dl>

        <dl class="row" v-if="booking.special_request">
          <dt>
            {{ $t("BOOKINGS.SPECIAL_REQUEST") }}
          </dt>
          <dd>
            {{ booking.special_request }}
          </dd>
        </dl>

        <!-- endregion -->

        <h4 class="title-red">{{ $t("COMMON.OCCUPATION") }}</h4>

        <dl class="row">
          <dt>
            {{ $t("COMMON.PEOPLE_NUMBER") }}
          </dt>
          <dd>
            {{ booking.children_count + booking.adults_count }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("SPOTS.SPOT_ADULT_CAPACITY") }}
          </dt>
          <dd>
            {{ booking.adults_count }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("SPOTS.SPOT_CHILDREN_CAPACITY") }}
          </dt>
          <dd>
            {{ booking.children_count ?? 0 }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("SPOTS.SPOT_PETS_CAPACITY") }}
          </dt>
          <dd>
            {{ booking.pets_count ?? 0 }}
          </dd>
        </dl>

        <h4 class="title-red">{{ $t("COMMON.OTHER") }}</h4>

        <dl class="row">
          <dt>
            {{ $t("COMMON.CREATED_AT") }}
          </dt>
          <dd>
            {{ $formatDate(booking.created_at, "LL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.CREATED_BY") }}
          </dt>
          <dd v-if="booking.createdBy">
            {{ booking.createdBy.lastname }}
            {{ booking.createdBy.firstname }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.UPDATED_AT") }}
          </dt>
          <dd>
            {{ $formatDate(booking.updated_at, "LL") }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BOOKING_FORM_TYPE_LAND,
  BOOKING_FORM_TYPE_SEASONAL,
} from "@/constants/spotCategories";

import { STATUS_DRAFT, STATUS_CANCELED } from "@/constants/bookings";

import { Button } from "element-ui";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIALLY_PAID,
  INVOICE_STATUS_VALIDATED,
} from "@/constants/invoices";
import SalesInvoiceViewAddPaymentModal from "@/views/Pages/SalesModule/SalesInvoiceManagement/partials/SalesInvoiceViewAddPaymentModal.vue";

export default {
  name: "booking-view-global",

  props: {
    booking: {
      type: Object,
      required: true,
    },
    salesInvoice: {
      type: Object,
      required: false,
    },
    salesOrder: {
      type: Object,
      required: false,
    },
  },

  components: {
    SalesInvoiceViewAddPaymentModal,
    [Button.name]: Button,
  },

  data() {
    return {
      paymentStep: false,
      paymentProcessing: false,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      INVOICE_STATUS_PAID: INVOICE_STATUS_PAID,
      INVOICE_STATUS_VALIDATED: INVOICE_STATUS_VALIDATED,
      INVOICE_STATUS_PARTIALLY_PAID: INVOICE_STATUS_PARTIALLY_PAID,
      STATUS_DRAFT: STATUS_DRAFT,
      STATUS_CANCELED: STATUS_CANCELED,
    };
  },

  computed: {
    bookingableName() {
      if (this.booking.bookingable.type == "bookings") {
        return this.booking.bookingable.company_name;
      } else if (this.booking.bookingable.type == "customers") {
        return this.booking.bookingable.customer_name;
      }
      return "N/A";
    },
    bookingableTypeName() {
      if (this.booking.bookingable.type == "bookings") {
        return this.$t("COMMON.SUPPLIER");
      } else if (this.booking.bookingable.type == "customers") {
        return this.$t("COMMON.CUSTOMER");
      }
      return "N/A";
    },
    isLandFormType: function () {
      return (
        this.booking.spot.category.booking_form_type === BOOKING_FORM_TYPE_LAND
      );
    },
    isSeasonalFormType() {
      return (
        this.booking.spot.category.booking_form_type ===
        BOOKING_FORM_TYPE_SEASONAL
      );
    },
    isLandSpotType() {
      return this.isSeasonalFormType || this.isLandFormType;
    },
  },

  created() {},

  methods: {
    bookingUpdated() {
      this.$emit("bookingUpdated", true);
    },
    // region paiement
    onPaymentBtnClick() {
      this.paymentStep = !this.paymentStep;
      this.paymentProcessing = false;
    },
    onPaymentCancelled() {
      this.paymentStep = false;
      this.paymentProcessing = false;
    },
    onChargeDifferenceProcessing() {
      this.paymentProcessing = true;
    },
    onChargeDifferenceFailed() {
      this.paymentProcessing = false;
    },
    onChargeDifferenceDone() {
      this.onPaymentCancelled();
      this.$emit("onChargeDifferenceDone");
    },
    // endregion
  },

  mounted() {},

  watch: {
    booking(booking) {},
  },
};
</script>

<style lang="scss">
.text-orange {
  --darken-primary: #{darken(#ef8152, 10%)};
  color: --darken-primary;
}
</style>
